// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".specification_capabilityName__gw47b {\n  width: 40%;\n}\n.specification_capability__VhXN8 {\n  width: 60%;\n}\n.specification_capability__VhXN8,\n.specification_capabilityName__gw47b {\n  margin: 0;\n  padding: 10px;\n  vertical-align: top;\n  font-size: 14px;\n  line-height: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/single-product-page/components/specification/specification.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;AACA;;EAEE,SAAS;EACT,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".capabilityName {\n  width: 40%;\n}\n.capability {\n  width: 60%;\n}\n.capability,\n.capabilityName {\n  margin: 0;\n  padding: 10px;\n  vertical-align: top;\n  font-size: 14px;\n  line-height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"capabilityName": "specification_capabilityName__gw47b",
	"capability": "specification_capability__VhXN8"
};
export default ___CSS_LOADER_EXPORT___;
