export class RequestError extends Error {
  /**
   * Status code of error
   *
   * @private
   */
  private readonly _statusCode: number;

  private readonly _statusText: string;

  private readonly _data: any;

  constructor(statusCode: number, statusText: string, data: string) {
    super();
    this._statusCode = statusCode;
    this._statusText = statusText;
    this._data = data;
  }

  get statusCode(): number {
    return this._statusCode;
  }

  get statusText(): string {
    return this._statusText;
  }

  get data(): any {
    return this._data;
  }
}
