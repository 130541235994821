import styles from "./footer.module.css";
import logo from "assets/atlas_power_logo_blue_orange.png";
import { ReactComponent as Location } from "assets/location.svg";
import { ReactComponent as Phone } from "assets/phone.svg";
import { ReactComponent as Email } from "assets/email.svg";
import { IPageLayoutInteractor } from "components/page-layout/interactor";

interface IProps {
  interactor: IPageLayoutInteractor;
}

export const AppFooter: React.FC<IProps> = ({ interactor }) => {
  return (
    <>
      <div className={styles.pageFooter}>
        <div className={styles.footerInfo}>
          <div className={styles.logoAndSocials}>
            <img src={logo} alt="logo" className={styles.logo} />
            {/* <Socials interactor={interactor} /> */}
          </div>
          <ContactUs interactor={interactor} />
          <AboutUs interactor={interactor} />
        </div>
        <div className={styles.copyrightsAndSocials}>
          <p className={styles.copyrights}>{interactor.appFooter.copyRights}</p>
          {/* <Socials interactor={interactor} /> */}
        </div>
      </div>
    </>
  );
};

export const ContactUs: React.FC<IProps> = ({ interactor }) => {
  return (
    <div className={styles.contactUs}>
      <h2>{interactor.appFooter.contactUs.header}</h2>
      <div className={styles.contactUsInfo}>
        <Location className={`${styles.infoIcon} ${styles.location}`} />
        <p>{interactor.appFooter.contactUs.address}</p>
      </div>
      <div className={styles.contactUsInfo}>
        <Phone className={styles.infoIcon} />
        <div>
          <p>{interactor.appFooter.contactUs.number}</p>
        </div>
      </div>
      <div className={styles.contactUsInfo}>
        <Email className={styles.infoIcon} />
        <div className={styles.emails}>
          {interactor.appFooter.contactUs.emails.map((email) => {
            return (
              <p key={email.label}>
                <a href={`mailto:${email.email}`}>{email.email}</a>
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const AboutUs: React.FC<IProps> = ({ interactor }) => {
  return (
    <div className={styles.aboutUs}>
      <h2>{interactor.appFooter.aboutUs.header}</h2>
      <p>{interactor.appFooter.aboutUs.content}</p>
    </div>
  );
};

const Socials: React.FC<IProps> = ({ interactor }) => {
  return (
    <div className={styles.socials}>
      {interactor.appFooter.socials.map((social) => {
        return (
          <a key={social.link} href={social.link}>
            <social.icon className={styles.social} />
          </a>
        );
      })}
    </div>
  );
};
