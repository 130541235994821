import axios, { AxiosInstance } from "axios";
import { API_HOST } from "../index";
import { HostDown } from "../errors/HostDown";
import { RequestError } from "../errors/RequestError";
import { NotFoundError } from "../errors/NotFoundError";
import { UnprocessableEntity } from "../errors/UnprocessableEntity";
import { ConflictError } from "../errors/ConflictError";
import { APIBasicError } from "../errors/APIBasicError";
import { UnauthorizedError } from "../errors/UnauthorizedError";

export abstract class AbstractEndpoint {
  /**
   * API host URL
   * Example: http://localhost:3000
   *
   * @private
   */
  private readonly baseUrl: string = API_HOST;

  /**
   * Endpoint subclass must provide endpoint URL
   * Example: articles
   */
  private readonly endpointUrl: string;

  /**
   * Axios instance
   *
   * @private
   */
  private readonly axiosClient: AxiosInstance;

  protected constructor(endpointUrl: string) {
    this.endpointUrl = endpointUrl;

    this.axiosClient = axios.create({
      baseURL: `${this.baseUrl}/${this.endpointUrl}/`,
      headers: AbstractEndpoint.constructHeaders(),
      withCredentials: true,
    });

    this.axiosClient.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.message === "Network Error") {
          return Promise.reject(new HostDown());
        }
        return Promise.reject(error);
      }
    );

    this.axiosClient.interceptors.response.use(
      (res) => res,
      async (err) => {
        const originalConfig = err.config;
        if (
          originalConfig.url !== "login" &&
          originalConfig.url !== "refresh" &&
          err.response
        ) {
          // Access Token was expired
          if (err.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;
            try {
              await this.get("refresh");
              return await this.axiosClient(originalConfig);
            } catch (_error) {
              return Promise.reject(_error);
            }
          }
        }
        return Promise.reject(err);
      }
    );
  }

  /**
   * GET request
   *
   * @param url without host or endpoint URL
   * @protected
   * @throws {RequestError | Error}
   */
  protected async get<Response>(url: string): Promise<Response | never> {
    try {
      const result = await this.axiosClient.get(url);
      return result.data;
    } catch (e: any) {
      if (e.response) {
        throw AbstractEndpoint.getTypedError(
          new RequestError(
            e.response.status,
            e.response.statusText,
            e.response.data
          )
        );
      }
      throw e;
    }
  }

  /**
   * POST request
   *
   * @param url without host or endpoint URL
   * @param data request data
   * @protected
   * @throws {RequestError | Error}
   */
  protected async post<Response>(
    url: string,
    data: Record<string, any>
  ): Promise<Response | never> {
    try {
      const result = await this.axiosClient.post(url, data);
      return result.data;
    } catch (e: any) {
      if (e.response) {
        throw AbstractEndpoint.getTypedError(
          new RequestError(
            e.response.status,
            e.response.statusText,
            e.response.data
          )
        );
      }
      throw e;
    }
  }

  /**
   * PATCH request
   *
   * @param url without host or endpoint URL
   * @param data request data
   * @protected
   * @throws {RequestError | Error}
   */
  protected async patch<Response>(
    url: string,
    data: Record<string, any>
  ): Promise<Response | never> {
    try {
      const result = await this.axiosClient.patch(url, data);
      return result.data;
    } catch (e: any) {
      if (e.response) {
        throw AbstractEndpoint.getTypedError(
          new RequestError(
            e.response.status,
            e.response.statusText,
            e.response.data
          )
        );
      }
      throw e;
    }
  }

  /**
   * Generate headers for Fetch
   *
   * @private
   */
  private static constructHeaders() {
    const headers: Record<string, string> = {
      "Content-Type": "application/json",
    };

    return headers;
  }

  private static getTypedError(e: RequestError) {
    switch (e.statusCode) {
      case 403:
        return new UnauthorizedError();
      case 404:
        return new NotFoundError(e.data);
      case 409:
        return new ConflictError(e.data.message);
      case 422:
        const keyError = Object.keys(e.data.message[0].constraints)[0];
        return new UnprocessableEntity(e.data.message[0].constraints[keyError]);
      default:
        return new APIBasicError(e.statusCode, e.message, e.data);
    }
  }
}
