import { DefaultPageLayout } from "components/page-layout";
import React from "react";
import { Navigate } from "react-router-dom";
import { SubmitButton } from "mui/submitButton";
import styles from "./index.module.css";

import { IPassRestorePageInteractor } from "./interactor";

export interface IProps {
  interactor: IPassRestorePageInteractor;
}
export const PassRestorePageRouter: React.FC<IProps> = ({ interactor }) => {
  if (interactor.session) {
    return <Navigate to="/" />;
  }

  return (
    <DefaultPageLayout pageName={interactor.pageName}>
      <form
        className={styles.restorationForm}
        onSubmit={(e) => {
          e.preventDefault();
          interactor.recoverPassword();
        }}
      >
        <input
          className={styles.restorationInput}
          placeholder={interactor.email.placeholder}
          value={interactor.email.value}
          inputMode="email"
          autoComplete="email"
          onChange={(e) => interactor.email.setEmail(e.target.value)}
        ></input>
        <div className={styles.buttonSection}>
          <SubmitButton width="110px">
            {interactor.submitButtonText}
          </SubmitButton>
        </div>
      </form>
    </DefaultPageLayout>
  );
};
