import { AuthEndpoint } from "./endpoints/auth";
import { OrderEndpoint } from "./endpoints/order";
import { SharedEndpoint } from "./endpoints/shared";
import { UserEndpoint } from "./endpoints/user";

export const API_HOST = process.env.REACT_APP_API_HOST;

export const API = {
  auth: new AuthEndpoint(),
  user: new UserEndpoint(),
  shared: new SharedEndpoint(),
  order: new OrderEndpoint(),
};
