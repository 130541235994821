import { SessionContext } from "components/authWrapper";
import React from "react";
import { API } from "services/api";
import { User } from "types";
import { companyAddresses } from "constants/addresses";
import { companyEmails } from "constants/emails";
import { companyNumbers } from "constants/numbers";
import { companySocials } from "constants/socials";
import { Navigate, useNavigate } from "react-router-dom";

export interface IPageLayoutInteractor {
  session: User | null;
  getUserName: () => string;
  handleLogout: () => void;
  appFooter: {
    copyRights: string;
    contactUs: {
      header: string;
      address: string;
      number: string;
      emails: { label: string; email: string }[];
    };
    aboutUs: { header: string; content: string };
    socials: Social[];
  };
  appHeader: { navButtons: (Button | null)[]; loginButton: Button };
}

type Button = { link: string; buttonText: string };

type Social = {
  link: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
};

export const usePageLayoutInteractor = (): IPageLayoutInteractor => {
  const { session, discardSession } = React.useContext(SessionContext);

  function getUserName() {
    if (session?.full_name) {
      return session.full_name;
    }
    return "";
  }
  const navigate = useNavigate();
  const handleLogout = () => {
    void API.auth.logout().then(() => {
      discardSession();
      navigate("/");
    });
  };
  return {
    session: session,
    getUserName: getUserName,
    handleLogout: handleLogout,
    appFooter: {
      copyRights: "Copyright ©2022 Atlas Power Products. All rights reserved.",
      contactUs: {
        header: "Contact Us",
        address: companyAddresses.HEAD_OF_OFFICE.address,
        number: companyNumbers[0].number,
        emails: companyEmails,
      },
      aboutUs: {
        header: "About us",
        content:
          "Atlas Power Products is a Michigan based switchboard manufacturer with variety of power distribution product offerings. Atlas switchboard is a fully customizable solution designed to improve efficiency, enhance safety, and save space. All solutions are custom built using a modular design that can be configured to suit project requirements.",
      },
      socials: companySocials,
    },
    appHeader: {
      navButtons: [
        { link: "/", buttonText: "Home" },
        { link: "/services", buttonText: "Services" },
        { link: "/products", buttonText: "Products" },
        { link: "/contact-us", buttonText: "Contact" },
        session && { link: "/orders/create", buttonText: "Configurator" },
      ],
      loginButton: { link: "/login", buttonText: "Log in" },
    },
  };
};
