import { DefaultPageLayout } from "components/page-layout";
import React from "react";
import { AboutCompany } from "./components/aboutCompany/aboutCompany";
import {
  ServicesContent,
  ServicesHeader,
} from "./components/services/services";
import { Team } from "./components/team/team";

import { IHomePageInteractor } from "./interactor";

export interface IProps {
  interactor: IHomePageInteractor;
}
export const HomePageRouter: React.FC<IProps> = ({ interactor }) => {
  return (
    <DefaultPageLayout pageName={interactor.pageName} header={false}>
      <AboutCompany interactor={interactor} />
      <ServicesHeader interactor={interactor} />
      <ServicesContent interactor={interactor} />
      <Team interactor={interactor} />
    </DefaultPageLayout>
  );
};
