import { ReactComponent as Facebook } from "assets/facebook.svg";
import { ReactComponent as Twitter } from "assets/twitter.svg";
import { ReactComponent as Linkedin } from "assets/linkedin.svg";
import { ReactComponent as Gplus } from "assets/gplus.svg";
import { ReactComponent as Pinterest } from "assets/pinterest.svg";

export const companySocials = [
  { link: "https://www.facebook.com", icon: Facebook },
  { link: "https://www.twitter.com", icon: Twitter },
  { link: "https://www.linkedin.com", icon: Linkedin },
  { link: "https://www.google.com", icon: Gplus },
  { link: "https://www.pinterest.com", icon: Pinterest },
];
