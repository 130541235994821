import React from "react";

import { useAllOrdersInteractor } from "./interactor";
import { AllOrdersRouter } from "./router";

export interface IProps {}
export const AllOrdersPage: React.FC<IProps> = () => {
  const interactor = useAllOrdersInteractor();
  return <AllOrdersRouter interactor={interactor} />;
};
