import React from "react";

import { IContactPageInteractor } from "./interactor";
import styles from "./index.module.css";
import { ContactForm } from "./components/contact-form";
import { Map } from "./components/map";
import { ContactInfo } from "./components/contact-info";
import { DefaultPageLayout } from "components/page-layout";

export interface IProps {
  interactor: IContactPageInteractor;
}
export const ContactPageRouter: React.FC<IProps> = ({ interactor }) => {
  return (
    <DefaultPageLayout pageName={interactor.pageName}>
      <Map interactor={interactor} />
      <div className={styles.contactSection}>
        <ContactInfo interactor={interactor} />
        <ContactForm interactor={interactor} />
      </div>
    </DefaultPageLayout>
  );
};
