// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".single-order-page_pageSectionHeader__uDgwM {\n  text-align: center;\n  padding-bottom: 30px;\n}\n.single-order-page_orderInfo__eNWAq {\n  display: flex;\n  flex-direction: column;\n  padding-bottom: 50px;\n}\n.single-order-page_orderInfo__eNWAq > div {\n  display: grid;\n  grid-template-columns: 200px 1fr;\n  min-width: 130px;\n}\n.single-order-page_orderInfo__eNWAq > div > * {\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  padding: 10px;\n}\n.single-order-page_statusDropdown__-STMq {\n  height: 50px;\n  width: 110px;\n  font-size: 15px;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n}\n.single-order-page_singleOrderPage__jQ-wu {\n  padding: 60px 10%;\n}\n.single-order-page_application__TaC6H {\n  width: 100%;\n  height: 80vh;\n  border: 1px solid rgba(0, 0, 0, 0.2);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/single-order-page/index.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oBAAoB;AACtB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,oBAAoB;AACtB;AACA;EACE,aAAa;EACb,gCAAgC;EAChC,gBAAgB;AAClB;AACA;EACE,oCAAoC;EACpC,aAAa;AACf;AACA;EACE,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,oCAAoC;AACtC;AACA;EACE,iBAAiB;AACnB;AACA;EACE,WAAW;EACX,YAAY;EACZ,oCAAoC;AACtC","sourcesContent":[".pageSectionHeader {\n  text-align: center;\n  padding-bottom: 30px;\n}\n.orderInfo {\n  display: flex;\n  flex-direction: column;\n  padding-bottom: 50px;\n}\n.orderInfo > div {\n  display: grid;\n  grid-template-columns: 200px 1fr;\n  min-width: 130px;\n}\n.orderInfo > div > * {\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  padding: 10px;\n}\n.statusDropdown {\n  height: 50px;\n  width: 110px;\n  font-size: 15px;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n}\n.singleOrderPage {\n  padding: 60px 10%;\n}\n.application {\n  width: 100%;\n  height: 80vh;\n  border: 1px solid rgba(0, 0, 0, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageSectionHeader": "single-order-page_pageSectionHeader__uDgwM",
	"orderInfo": "single-order-page_orderInfo__eNWAq",
	"statusDropdown": "single-order-page_statusDropdown__-STMq",
	"singleOrderPage": "single-order-page_singleOrderPage__jQ-wu",
	"application": "single-order-page_application__TaC6H"
};
export default ___CSS_LOADER_EXPORT___;
