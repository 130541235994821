import { DefaultPageLayout } from "components/page-layout";
import React from "react";
import { SubmitButton } from "mui/submitButton";
import styles from "./index.module.css";

import { ICreateAccountPageInteractor } from "./interactor";
import { Oval } from "react-loader-spinner";

export interface IProps {
  interactor: ICreateAccountPageInteractor;
}

export const CreateAccountPageRouter: React.FC<IProps> = ({ interactor }) => {
  if (!interactor.isAdmin) {
    return (
      <DefaultPageLayout pageName={interactor.pageName}>
        <div
          style={{
            display: "grid",
            justifyContent: "center",
            padding: "20vh 0 20vh ",
          }}
        >
          <Oval color="#f5f5f5" secondaryColor="#343434" />
        </div>
      </DefaultPageLayout>
    );
  }
  return (
    <DefaultPageLayout pageName={interactor.pageName}>
      <form
        className={styles.createUserForm}
        onSubmit={(e) => {
          e.preventDefault();
          interactor.createAccount();
        }}
      >
        <h2>{interactor.header}</h2>
        <input
          className={styles.createUserInput}
          placeholder={interactor.placeholders.email}
          type="email"
          inputMode="email"
          autoComplete="email"
          required
          maxLength={255}
          value={interactor.userInfo.email}
          onChange={(e) => interactor.setUserInfo.setEmail(e.target.value)}
        ></input>
        <input
          className={styles.createUserInput}
          placeholder={interactor.placeholders.name}
          type="text"
          inputMode="text"
          autoComplete="name"
          required
          minLength={8}
          maxLength={255}
          value={interactor.userInfo.name}
          onChange={(e) => interactor.setUserInfo.setName(e.target.value)}
        ></input>
        <input
          className={styles.createUserInput}
          placeholder={interactor.placeholders.jobTitle}
          type="text"
          inputMode="text"
          maxLength={255}
          value={interactor.userInfo.jobTitle}
          onChange={(e) => interactor.setUserInfo.setJobTitle(e.target.value)}
        ></input>
        <input
          className={styles.createUserInput}
          placeholder={interactor.placeholders.companyName}
          inputMode="email"
          autoComplete="organization-title"
          maxLength={255}
          value={interactor.userInfo.companyName}
          onChange={(e) =>
            interactor.setUserInfo.setCompanyName(e.target.value)
          }
        ></input>
        <input
          className={styles.createUserInput}
          placeholder={interactor.placeholders.companyPhone}
          type="tel"
          inputMode="tel"
          autoComplete="tel"
          maxLength={255}
          value={interactor.userInfo.companyPhone}
          onChange={(e) =>
            interactor.setUserInfo.setCompanyPhone(e.target.value)
          }
        ></input>
        <input
          className={styles.createUserInput}
          placeholder={interactor.placeholders.companyAddress}
          autoComplete="street-address"
          maxLength={255}
          value={interactor.userInfo.companyAddress}
          onChange={(e) =>
            interactor.setUserInfo.setCompanyAddress(e.target.value)
          }
        ></input>
        <SubmitButton width="140px">
          {interactor.createUserButtonText}
        </SubmitButton>
      </form>
    </DefaultPageLayout>
  );
};
