import React from "react";

import { useContactPageInteractor } from "./interactor";
import { ContactPageRouter } from "./router";

export interface IProps {}
export const ContactPage: React.FC<IProps> = () => {
  const interactor = useContactPageInteractor();
  return <ContactPageRouter interactor={interactor} />;
};
