// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".orders_pageContent__16E2Z {\n  padding: 70px 5%;\n}\n.orders_pageHeader__IIxJ4 {\n  text-align: center;\n  padding-bottom: 20px;\n}\n.orders_ordersHeader__dV0lc,\n.orders_orderField__Rf0ql {\n  display: grid;\n  grid-template-columns: 15% 15% 40% 15% 15%;\n  grid-template-columns: minmax(120px, 10%) 10% minmax(40%, 60%) 10% 10%;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n}\n.orders_ordersHeader__dV0lc > *,\n.orders_orderField__Rf0ql > * {\n  position: relative;\n  width: 100%;\n  border-left: 1px solid rgba(0, 0, 0, 0.1);\n  padding: 10px;\n}\n.orders_noOrdersMessage__q8TKE {\n  padding: 70px 0;\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/user-profile-page/components/orders/orders.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,oBAAoB;AACtB;AACA;;EAEE,aAAa;EACb,0CAA0C;EAC1C,sEAAsE;EACtE,oCAAoC;AACtC;AACA;;EAEE,kBAAkB;EAClB,WAAW;EACX,yCAAyC;EACzC,aAAa;AACf;AACA;EACE,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".pageContent {\n  padding: 70px 5%;\n}\n.pageHeader {\n  text-align: center;\n  padding-bottom: 20px;\n}\n.ordersHeader,\n.orderField {\n  display: grid;\n  grid-template-columns: 15% 15% 40% 15% 15%;\n  grid-template-columns: minmax(120px, 10%) 10% minmax(40%, 60%) 10% 10%;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n}\n.ordersHeader > *,\n.orderField > * {\n  position: relative;\n  width: 100%;\n  border-left: 1px solid rgba(0, 0, 0, 0.1);\n  padding: 10px;\n}\n.noOrdersMessage {\n  padding: 70px 0;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContent": "orders_pageContent__16E2Z",
	"pageHeader": "orders_pageHeader__IIxJ4",
	"ordersHeader": "orders_ordersHeader__dV0lc",
	"orderField": "orders_orderField__Rf0ql",
	"noOrdersMessage": "orders_noOrdersMessage__q8TKE"
};
export default ___CSS_LOADER_EXPORT___;
