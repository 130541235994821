import { DefaultPageLayout } from "components/page-layout";
import React from "react";
import { Link } from "react-router-dom";
import { ClassicButton } from "mui/classicButton";
import styles from "./index.module.css";

import { ISingleOrderPageInteractor } from "./interactor";
import { Oval } from "react-loader-spinner";

export interface IProps {
  interactor: ISingleOrderPageInteractor;
}
export const SingleOrderPageRouter: React.FC<IProps> = ({ interactor }) => {
  if (!interactor.order) {
    return (
      <DefaultPageLayout pageName={interactor.pageName}>
        <div
          style={{
            display: "grid",
            justifyContent: "center",
            padding: "20vh 0 20vh ",
          }}
        >
          <Oval color="#f5f5f5" secondaryColor="#343434" />
        </div>
      </DefaultPageLayout>
    );
  }

  return (
    <DefaultPageLayout pageName={interactor.pageName}>
      <div className={styles.singleOrderPage}>
        <h2 className={styles.pageSectionHeader}>
          {interactor.orderInfoHeader}
        </h2>
        <div className={styles.orderInfo}>
          <div>
            <p>
              <b>{interactor.orderHeaders.id}</b>
            </p>
            <p>{interactor.order.id}</p>
          </div>
          <div>
            <p>
              <b>{interactor.orderHeaders.orderer}</b>
            </p>
            <p>
              {interactor.isAdmin ? (
                <Link
                  className="link"
                  to={`/clients/${interactor.order.owner.id}`}
                >
                  {interactor.order.owner.full_name}
                </Link>
              ) : (
                interactor.order.owner.full_name
              )}
            </p>
          </div>
          <div>
            <p>
              <b>{interactor.orderHeaders.status}</b>
            </p>
            {interactor.isAdmin ? (
              <p>
                <select
                  className={styles.statusDropdown}
                  value={interactor.status}
                  onChange={interactor.setStatus}
                >
                  {interactor.orderStatuses.map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
                <br />
                <ClassicButton
                  width="110px"
                  margin="10px 0 0 0 "
                  onClick={interactor.updateOrderStatus}
                >
                  {interactor.updateButtonText}
                </ClassicButton>
              </p>
            ) : (
              <p>{interactor.order.status}</p>
            )}
          </div>
          <div>
            <p>
              <b>{interactor.orderHeaders.created}</b>
            </p>
            <p>{interactor.order.create_at.slice(0, -8).replace("T", " ")}</p>
          </div>
          <div>
            <p>
              <b>{interactor.orderHeaders.updated}</b>
            </p>
            <p>{interactor.order.updated_at.slice(0, -8).replace("T", " ")}</p>
          </div>
          <div>
            <p>
              <b>{interactor.orderHeaders.job_name}</b>
            </p>
            <p> {interactor.order.job_name}</p>
          </div>
          <div>
            <p>
              <b>{interactor.orderHeaders.panel_name}</b>
            </p>
            <p> {interactor.order.panel_name}</p>
          </div>
          <div>
            <p>
              <b>{interactor.orderHeaders.special_requirements}</b>
            </p>
            <p> {interactor.order.special_requirements}</p>
          </div>
        </div>
        <h2 className={styles.pageSectionHeader}>
          {interactor.orderViewHeader}
        </h2>
        <iframe
          className={styles.application}
          title="Order"
          src={`https://deployed.dynamaker.com/applications/4uAbo5SODYR/${
            "?configuration=" + interactor.configuration
          }`}
        ></iframe>
      </div>
    </DefaultPageLayout>
  );
};
