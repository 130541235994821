import { IProps } from "pages/single-product-page/router";
import styles from "./specification.module.css";

export const Specification: React.FC<IProps> = ({ interactor }) => {
  if (!interactor.product) return null;
  return (
    <table className={styles.specification}>
      <tbody>
        {interactor.product.capabilityNames.map((capabilityName, index) => {
          return (
            <tr key={capabilityName}>
              <td className={styles.capabilityName}>{capabilityName}</td>
              <td className={styles.capability}>
                {interactor.product?.capabilities[index]}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
