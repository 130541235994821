import { SessionContext } from "components/authWrapper";
import React, { useState } from "react";
import { API } from "services/api";
import { User } from "types";

export interface IPassRestorePageInteractor {
  pageName: string;
  session: User | null;
  recoverPassword: () => void;
  email: {
    value: string;
    placeholder: string;
    setEmail: (email: string) => void;
  };
  submitButtonText: string;
}

export const usePassRestorePageInteractor = (): IPassRestorePageInteractor => {
  const [email, setEmail] = useState("");

  const { session } = React.useContext(SessionContext);

  function recoverPassword() {
    API.auth.recoverPassword(email);
  }

  return {
    pageName: "Restore Password",
    session: session,
    recoverPassword: recoverPassword,
    email: {
      value: email,
      placeholder: "Enter your email",
      setEmail: setEmail,
    },
    submitButtonText: "Submit",
  };
};
