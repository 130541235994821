// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".create-account-page_createUserForm__EdLw\\+ {\n  padding: 70px 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 30px;\n}\n\n.create-account-page_createUserInput__PHrx9 {\n  width: 600px;\n  padding: 10px;\n  height: 50px;\n  font-size: 15px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/create-account-page/index.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".createUserForm {\n  padding: 70px 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 30px;\n}\n\n.createUserInput {\n  width: 600px;\n  padding: 10px;\n  height: 50px;\n  font-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"createUserForm": "create-account-page_createUserForm__EdLw+",
	"createUserInput": "create-account-page_createUserInput__PHrx9"
};
export default ___CSS_LOADER_EXPORT___;
