// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".contact-info_contactInfoSection__LHntw {\n    display: flex;\n    flex-direction: column;\n    gap: 30px;\n    width: 30%;\n    min-width: 300px;\n}\n\n.contact-info_contactInfo__-Jyge {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 40px;\n}\n.contact-info_contactInfo__-Jyge > div {\n    display: flex;\n    font-weight: 300;\n    opacity: 0.7;\n}\n\n.contact-info_icon__kj5LC {\n    height: 25px;\n    min-width: 25px;\n    margin-right: 20px;\n}\n\n.contact-info_emails__GQ5Zz > p {\n    padding-bottom: 10px;\n}\n\n@media (max-width: 1280px) {\n    .contact-info_contactInfo__-Jyge {\n        flex-direction: column;\n        gap: 20px;\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/contact-page/components/contact-info/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,SAAS;AACb;AACA;IACI,aAAa;IACb,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI;QACI,sBAAsB;QACtB,SAAS;IACb;AACJ","sourcesContent":[".contactInfoSection {\n    display: flex;\n    flex-direction: column;\n    gap: 30px;\n    width: 30%;\n    min-width: 300px;\n}\n\n.contactInfo {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 40px;\n}\n.contactInfo > div {\n    display: flex;\n    font-weight: 300;\n    opacity: 0.7;\n}\n\n.icon {\n    height: 25px;\n    min-width: 25px;\n    margin-right: 20px;\n}\n\n.emails > p {\n    padding-bottom: 10px;\n}\n\n@media (max-width: 1280px) {\n    .contactInfo {\n        flex-direction: column;\n        gap: 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contactInfoSection": "contact-info_contactInfoSection__LHntw",
	"contactInfo": "contact-info_contactInfo__-Jyge",
	"icon": "contact-info_icon__kj5LC",
	"emails": "contact-info_emails__GQ5Zz"
};
export default ___CSS_LOADER_EXPORT___;
