import { DefaultPageLayout } from "components/page-layout";
import React from "react";
import { Link } from "react-router-dom";
import { SubmitButton } from "mui/submitButton";
import styles from "./index.module.css";

import { ILoginPageInteractor } from "./interactor";

export interface IProps {
  interactor: ILoginPageInteractor;
}
export const LoginPageRouter: React.FC<IProps> = ({ interactor }) => {
  return (
    <DefaultPageLayout pageName={interactor.pageName}>
      <form
        className={styles.loginForm}
        onSubmit={(e) => {
          e.preventDefault();
          interactor.loginUser();
        }}
      >
        <input
          className={styles.loginInput}
          placeholder={interactor.email.placeholder}
          value={interactor.email.value}
          inputMode="email"
          autoComplete="email"
          onChange={(e) => interactor.setEmail(e.target.value)}
        ></input>
        <input
          className={styles.loginInput}
          placeholder={interactor.password.placeholder}
          type="password"
          value={interactor.password.value}
          autoComplete="current-password"
          onChange={(e) => interactor.setPassword(e.target.value)}
        ></input>
        <div className={styles.loginOrRestore}>
          <SubmitButton width="110px">{interactor.buttonText}</SubmitButton>
          <Link to="/restore">
            <b className={styles.forgotPassword}>{interactor.forgotPassword}</b>
          </Link>
        </div>
      </form>
    </DefaultPageLayout>
  );
};
