// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".description_listItem__e6dS0 {\n  font-size: 15px;\n  line-height: 25px;\n}\n.description_listName__-Sy-4 {\n  padding: 20px 0 5px 0;\n  font-size: 15px;\n  font-weight: 600;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/single-product-page/components/description/description.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,qBAAqB;EACrB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".listItem {\n  font-size: 15px;\n  line-height: 25px;\n}\n.listName {\n  padding: 20px 0 5px 0;\n  font-size: 15px;\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listItem": "description_listItem__e6dS0",
	"listName": "description_listName__-Sy-4"
};
export default ___CSS_LOADER_EXPORT___;
