import React from "react";

import { useSingleOrderPageInteractor } from "./interactor";
import { SingleOrderPageRouter } from "./router";

export interface IProps {}
export const SingleOrderPage: React.FC<IProps> = () => {
  const interactor = useSingleOrderPageInteractor();
  return <SingleOrderPageRouter interactor={interactor} />;
};
