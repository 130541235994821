// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".related_relatedHeader__9o6bu {\n  padding: 40px 0 15px 19%;\n}\n\n.related_relatedProducts__pWUZM {\n  display: flex;\n  gap: 25px;\n  justify-content: center;\n}\n\n.related_product__y\\+fcE {\n  width: 30%;\n  text-align: center;\n  font-size: 15px;\n  line-height: 15px;\n}\n.related_product__y\\+fcE > img {\n  padding: 2% 4%;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  margin-bottom: 10px;\n}\n.related_product__y\\+fcE > a > p {\n  padding-top: 10px;\n}\n.related_pageContent__lE4le {\n  padding: 100px 20%;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/single-product-page/components/related/related.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,aAAa;EACb,SAAS;EACT,uBAAuB;AACzB;;AAEA;EACE,UAAU;EACV,kBAAkB;EAClB,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,cAAc;EACd,oCAAoC;EACpC,mBAAmB;AACrB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".relatedHeader {\n  padding: 40px 0 15px 19%;\n}\n\n.relatedProducts {\n  display: flex;\n  gap: 25px;\n  justify-content: center;\n}\n\n.product {\n  width: 30%;\n  text-align: center;\n  font-size: 15px;\n  line-height: 15px;\n}\n.product > img {\n  padding: 2% 4%;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  margin-bottom: 10px;\n}\n.product > a > p {\n  padding-top: 10px;\n}\n.pageContent {\n  padding: 100px 20%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"relatedHeader": "related_relatedHeader__9o6bu",
	"relatedProducts": "related_relatedProducts__pWUZM",
	"product": "related_product__y+fcE",
	"pageContent": "related_pageContent__lE4le"
};
export default ___CSS_LOADER_EXPORT___;
