import image1 from "pages/home-page/assets/projectsFourth.jpg";
import image2 from "assets/systemIntegrationServices.jpg";
import image3 from "pages/home-page/assets/aboutCompanyFirst.jpg";
import image4 from "pages/home-page/assets/projectsSecond.jpg";

export const products = [
  {
    id: "ul-891-switchboard",
    name: "UL 891 Switchboard",
    shortDescription:
      "Atlas Switchboard range is compliant with the specifications of the UL891 quality control standard, which is one of the most common and most widely trusted standards for switchboards.",
    description:
      "The UL standards are essentially a set of safety regulations that have been certified by OSHA (Occupational Safety and Health Administration) in the U.S. These standards have been approved by the U.S. federal government for the safety of workers. \n\nThe specifications of the UL891 quality control standard apply to the switchboards rated for 600V or less in accordance with the National Electric Code (NEC) and ANSI/NFPA 70.",
    images: [image1, image2, image3, image4],
    price: 2500,
    prevPrice: 3300,
    capabilityNames: [
      "Voltage:",
      "Ampacity:",
      "Short circuit current ratings up",
      "Maximum",
      "Implementation of LS Electric UL MCCB and UL ACB breakers up",
      "Additional",
    ],
    capabilities: [
      "up to 600 VAC",
      "up to 4,000A maximum bus rating for standard builds, capable of constructing up to 6,000A+ custom builds",
      "to 65 kAIC at 600 VAC",
      "1200A molded case circuit breaker in individual sections",
      "to 6000A max",
      "Main-Main, Main-Tie-Main, Main-Generator, and Paralleling applications",
    ],
    features: [
      "Enclosures of Type 1, 3R, 4, 4X, or 12 construction",
      "Welded or bolt-together designs",
      "Copper or aluminum bus construction",
      "ANSI 61 gray or custom colors available",
      "Front, rear, and/or side accessibility",
      "GFCI protection",
    ],
    options: [
      "High Resistance Ground",
      "Tin-plated copper bus construction",
      "Automatic & Manual Transfer Switches",
      "Surge Protection Devices (SPD)",
      "Distribution Transformers & Unit Substation Designs",
      "ERMS (arc-reduction maintenance switch)",
      "PLCs, HMI communication",
      "Power metering",
    ],
  },
  {
    id: "ul-panelboards",
    name: "UL Panelboards",
    shortDescription:
      "Atlas's UL distribution panelboards provide a compact and flexible solution for electric power distribution with integrated breakers, metering, and surge protection.",
    description:
      "Additionally, they're designed to meet UL 67 standards for use in data centers, industrial, commercial, and healthcare facilities for up to 600 Vac and 1200 A applications.",
    images: [image1, image2, image3, image4],
    price: 2400,
    prevPrice: 3200,
    capabilityNames: [
      "Voltage:",
      "Ampacity:",
      "Short circuit current ratings up",
      "Maximum:",
      "Implementation of",
      "Additional",
    ],
    capabilities: [
      "up to 600 VAC",
      "up to 1200A maximum bus rating",
      "to 65 kAIC at 600 VAC",
      "1200A molded case circuit breaker",
      "LS Electric UL MCCB",
      "Main-Main, Main-Tie-Main, Main-Generator, and Paralleling applications",
    ],
    features: [
      "Enclosures of Type 1, 3R, 4, 4X, or 12 construction",
      "Welded or bolt-together designs",
      "Copper or aluminum bus construction",
      "ANSI 61 gray or custom colors available",
      "Front, rear, and/or side accessibility",
      "GFCI protection",
    ],
    options: [
      "High Resistance Ground",
      "Tin-plated copper bus construction",
      "Automatic & Manual Transfer Switches",
      "Surge Protection Devices (SPD)",
      "PLCs, HMI communication",
      "Power metering",
    ],
  },
  {
    id: "industrial-control-panels",
    name: "Industrial Control Panels",
    shortDescription:
      "Atlas Power Products is a one-stop destination for all custom control panels. Industrial control panels are an important piece of equipment and are the core hardware for all your motor and process control.",
    description:
      "To manufacture custom control panel, you must rely on the assistance of skilled and well-trained technicians. Why? Consider this. A customized control panel requires an advanced configuration, the integration of several components, and creative automated solutions. \n\nHence, developing a customized control panel calls for an immense amount of skill and technique. We at Atlas Power Products have onboard with us the best technicians with prolonged experience of control panel design and implementation.",
    images: [image1, image2, image3, image4],
    price: 5000,
    prevPrice: 7000,
    capabilityNames: [
      "Voltage:",
      "Ampacity:",
      "Short circuit current ratings up",
      "Implementation of",
    ],
    capabilities: [
      "up to 600 VAC",
      "up to 1200A",
      "to 65 kAIC at 600 VAC",
      "LS Electric UL Components",
    ],
    features: [
      "Enclosures of Type 1, 3R, 4, 4X, or 12 construction",
      "Welded or bolt-together designs",
      "ANSI 61 gray or custom colors available",
      "Front, rear, and/or side accessibility",
      "Surge Protection Devices (SPD)",
    ],
    options: [
      "Variable Frequency Drive (VFD)",
      "Programmable Logic Controller (PLC)",
      "Human Machine Interface (HMI)",
      "GFCI protection",
      "Power metering",
    ],
  },
];
