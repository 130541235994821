import React from "react";

import { useEditOrderPageInteractor } from "./interactor";
import { EditOrderPageRouter } from "./router";

export interface IProps {}
export const EditOrderPage: React.FC<IProps> = () => {
  const interactor = useEditOrderPageInteractor();
  return <EditOrderPageRouter interactor={interactor} />;
};
