import React from "react";

import { useLoginPageInteractor } from "./interactor";
import { LoginPageRouter } from "./router";

export interface IProps {}
export const LoginPage: React.FC<IProps> = () => {
  const interactor = useLoginPageInteractor();
  return <LoginPageRouter interactor={interactor} />;
};
