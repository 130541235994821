// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/servicesHeader.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pageHeader_pageHeader__TGMXG {\n  background: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 0 -500px;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  padding: 100px 15%;\n  align-content: center;\n  justify-content: center;\n}\n.pageHeader_pageHeader__TGMXG > * {\n  color: #ffffff;\n  text-align: center;\n}\n.pageHeader_pageHeader__TGMXG > h1 {\n  font-size: 50px;\n  font-weight: 400;\n}\n", "",{"version":3,"sources":["webpack://./src/components/page-layout/components/pageHeader/pageHeader.module.css"],"names":[],"mappings":"AAAA;EACE;oDAC2C;EAC3C,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,kBAAkB;EAClB,qBAAqB;EACrB,uBAAuB;AACzB;AACA;EACE,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".pageHeader {\n  background: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),\n    url(\"assets/servicesHeader.jpg\") 0 -500px;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  padding: 100px 15%;\n  align-content: center;\n  justify-content: center;\n}\n.pageHeader > * {\n  color: #ffffff;\n  text-align: center;\n}\n.pageHeader > h1 {\n  font-size: 50px;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageHeader": "pageHeader_pageHeader__TGMXG"
};
export default ___CSS_LOADER_EXPORT___;
