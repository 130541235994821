import { SessionContext } from "components/authWrapper";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "services/api";

export interface ICreateAccountPageInteractor {
  pageName: string;
  isAdmin: boolean;
  header: string;
  createAccount: () => void;
  placeholders: {
    email: string;
    name: string;
    jobTitle: string;
    companyName: string;
    companyPhone: string;
    companyAddress: string;
  };
  userInfo: {
    email: string;
    name: string;
    jobTitle: string;
    companyName: string;
    companyPhone: string;
    companyAddress: string;
  };
  setUserInfo: {
    setEmail: (email: string) => void;
    setName: (name: string) => void;
    setJobTitle: (jobTitle: string) => void;
    setCompanyName: (companyName: string) => void;
    setCompanyPhone: (companyPhone: string) => void;
    setCompanyAddress: (companyAddress: string) => void;
  };
  createUserButtonText: string;
}

export const useCreateAccountPageInteractor =
  (): ICreateAccountPageInteractor => {
    const { isLoaded, session } = React.useContext(SessionContext);

    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
      if (session?.role === "ADMIN") {
        setIsAdmin(true);
        return;
      }
      isLoaded && navigate("/");
    }, [isLoaded]);

    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [companyPhone, setCompanyPhone] = useState("");
    const [companyAddress, setCompanyAddress] = useState("");

    function createAccount() {
      API.user.createAccount({
        email,
        full_name: name,
        job_title: jobTitle,
        company_name: companyName,
        company_phone: companyPhone,
        company_address: companyAddress,
      });
      setEmail("");
      setName("");
      setJobTitle("");
      setCompanyName("");
      setCompanyPhone("");
      setCompanyAddress("");
    }
    return {
      pageName: "Create user",
      isAdmin: isAdmin,
      header: "Fill in the form to create a new user",
      createAccount: createAccount,
      placeholders: {
        email: "Email",
        name: "Full Name",
        jobTitle: "Job Title",
        companyName: "Company Name",
        companyPhone: "Company Phone",
        companyAddress: "Company address",
      },
      userInfo: {
        email: email,
        name: name,
        jobTitle: jobTitle,
        companyName: companyName,
        companyPhone: companyPhone,
        companyAddress: companyAddress,
      },
      setUserInfo: {
        setEmail: setEmail,
        setName: setName,
        setJobTitle: setJobTitle,
        setCompanyName: setCompanyName,
        setCompanyPhone: setCompanyPhone,
        setCompanyAddress: setCompanyAddress,
      },
      createUserButtonText: "Add user",
    };
  };
