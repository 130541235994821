// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".clients-page_clientsTable__VorGg {\n  padding: 70px 5%;\n}\n.clients-page_clientsTableHeader__Q6czl,\n.clients-page_clientsList__1-hIm > div {\n  display: grid;\n  grid-template-columns: repeat(8, 12.5%);\n}\n.clients-page_clientsTableHeader__Q6czl > *,\n.clients-page_clientsList__1-hIm > div > * {\n  position: relative;\n  overflow-wrap: break-word;\n  padding: 5px;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n}\n.clients-page_clientsTableHeader__Q6czl {\n  border: 1px solid rgba(0, 0, 0, 0.3);\n}\n.clients-page_clientsList__1-hIm {\n  border: 1px solid rgba(0, 0, 0, 0.2);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/clients-page/index.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;;EAEE,aAAa;EACb,uCAAuC;AACzC;AACA;;EAEE,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,oCAAoC;AACtC;AACA;EACE,oCAAoC;AACtC;AACA;EACE,oCAAoC;AACtC","sourcesContent":[".clientsTable {\n  padding: 70px 5%;\n}\n.clientsTableHeader,\n.clientsList > div {\n  display: grid;\n  grid-template-columns: repeat(8, 12.5%);\n}\n.clientsTableHeader > *,\n.clientsList > div > * {\n  position: relative;\n  overflow-wrap: break-word;\n  padding: 5px;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n}\n.clientsTableHeader {\n  border: 1px solid rgba(0, 0, 0, 0.3);\n}\n.clientsList {\n  border: 1px solid rgba(0, 0, 0, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clientsTable": "clients-page_clientsTable__VorGg",
	"clientsTableHeader": "clients-page_clientsTableHeader__Q6czl",
	"clientsList": "clients-page_clientsList__1-hIm"
};
export default ___CSS_LOADER_EXPORT___;
