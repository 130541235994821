import { AbstractEndpoint } from "./abstract-endpoint";
import { User } from "types";

export class AuthEndpoint extends AbstractEndpoint {
  constructor() {
    super("auth");
  }

  /**
   * Login user with email & password
   *
   */
  public async login(
    email: string,
    password: string,
    rememberMe: boolean = true
  ) {
    const data = { email, password, rememberMe };
    try {
      const result = await this.post<SuccessLoginPayload>("login", data);
      return result;
    } catch (e: any) {
      throw e;
    }
  }

  public async recoverPassword(email: string) {
    try {
      const result = await this.post("forgot", { email });
      return result;
    } catch (e: any) {
      throw e;
    }
  }

  /**
   * Retrieve user by cookie token
   *
   */
  public async me() {
    try {
      const result = await this.get<SuccessGetMePayload>("me");
      return result;
    } catch (e: any) {
      throw e;
    }
  }

  /**
   * Delete user token cookie & clear state
   */
  public async logout() {
    try {
      await this.post("logout", {});
      return true;
    } catch (e: any) {
      throw new Error(e.message);
    }
  }

  /**
   * Create a new user
   */
}

export interface SuccessLoginPayload {
  user: User;
}

export interface SuccessGetMePayload {
  user: User;
}
