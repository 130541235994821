import React from "react";

import { useUserProfilePageInteractor } from "./interactor";
import { UserProfilePageRouter } from "./router";

export interface IProps {}
export const UserProfilePage: React.FC<IProps> = () => {
  const interactor = useUserProfilePageInteractor();
  return <UserProfilePageRouter interactor={interactor} />;
};
