// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".map_mapContainer__S0W0Y {\n  height: 420px;\n  margin-top: 100px;\n  padding: 0 15%;\n}\n@media (max-width: 1280px) {\n  .map_mapContainer__S0W0Y {\n    padding: 0 10%;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/contact-page/components/map/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,cAAc;AAChB;AACA;EACE;IACE,cAAc;EAChB;AACF","sourcesContent":[".mapContainer {\n  height: 420px;\n  margin-top: 100px;\n  padding: 0 15%;\n}\n@media (max-width: 1280px) {\n  .mapContainer {\n    padding: 0 10%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mapContainer": "map_mapContainer__S0W0Y"
};
export default ___CSS_LOADER_EXPORT___;
