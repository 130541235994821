import React from "react";
import styles from "./index.module.css";
import { ReactComponent as MapIcon } from "assets/map.svg";
import { ReactComponent as PhoneIcon } from "assets/phone.svg";
import { ReactComponent as EmailIcon } from "assets/email_open.svg";
import { IContactPageInteractor } from "../../interactor";

interface IProps {
  interactor: IContactPageInteractor;
}
export const ContactInfo: React.FC<IProps> = ({ interactor }) => {
  const renderEmails = () => {
    return interactor.contactInfo.emails.map((item, index) => {
      return (
        <p key={item.label}>
          {item.label} <a href={`mailto:${item.email}`}>{item.email}</a>
        </p>
      );
    });
  };

  return (
    <div className={styles.contactInfoSection}>
      <h2>{interactor.contactInfo.header}</h2>
      <div className={styles.contactInfo}>
        <div>
          <MapIcon className={`${styles.icon} ${styles.mapIcon}`} />
          <p>{interactor.contactInfo.address}</p>
        </div>
        <div>
          <PhoneIcon className={styles.icon} />
          <div>
            <p>{interactor.contactInfo.phoneNumber}</p>
          </div>
        </div>
        <div>
          <EmailIcon className={styles.icon} />
          <div className={styles.emails}>{renderEmails()}</div>
        </div>
      </div>
    </div>
  );
};
