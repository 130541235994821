import { Order, OrderStatus, User } from "types";
import { AbstractEndpoint } from "./abstract-endpoint";

export class OrderEndpoint extends AbstractEndpoint {
  constructor() {
    super("order");
  }

  public async createOrder(configuration: OrderConfiguration) {
    try {
      await this.post<SuccessCreatePayload>("", configuration);
      return true;
    } catch (e: any) {
      throw new Error(e.message);
    }
  }

  public async getOrderById(id: string) {
    try {
      const result: Order = await this.get(id);
      return result;
    } catch (e: any) {
      throw e;
    }
  }

  public async getAllOrders() {
    try {
      const result: Order[] = await this.get("all");
      return result;
    } catch (e: any) {
      throw e;
    }
  }

  public async updateOrderStatus(id: string, status: OrderStatus) {
    try {
      await this.patch(`${id}/status`, {
        status,
      });
      return true;
    } catch (e: any) {
      throw new Error(e.message);
    }
  }
  public async updateOrderConfiguration(
    id: string,
    configuration: OrderConfiguration
  ) {
    try {
      await this.patch(`${id}`, configuration);
      return true;
    } catch (e: any) {
      throw new Error(e.message);
    }
  }
}

export interface SuccessCreatePayload {
  id: string;
  owner: User;
  status: "Draft";
  configuration: string;
  create_at: string;
  updated_at: string;
}
export interface OrderConfiguration {
  configuration: string;
  job_name: string;
  panel_name: string;
  special_requirements: string;
}
