import { CreateUserData, User } from "types";
import { AbstractEndpoint } from "./abstract-endpoint";

export class UserEndpoint extends AbstractEndpoint {
  constructor() {
    super("user");
  }

  public async createAccount(user: CreateUserData) {
    try {
      await this.post<SuccessCreatePayload>("", user);
      return true;
    } catch (e: any) {
      throw new Error(e.message);
    }
  }

  public async getAllUsers() {
    try {
      const result: User[] = await this.get("all");
      return result;
    } catch (e: any) {
      throw new Error(e.message);
    }
  }

  public async getUser(id: string) {
    try {
      const result: User = await this.get(id);
      return result;
    } catch (e: any) {
      throw new Error(e.message);
    }
  }

  public async resetPassword(id: string) {
    try {
      await this.post(`${id}/reset-password`, {});
    } catch (e: any) {
      throw new Error(e.message);
    }
  }

  public async updateUser(id: string, data: UserInfo) {
    try {
      await this.patch(id, data);
    } catch (e: any) {
      throw new Error(e.message);
    }
  }
}

export interface SuccessCreatePayload {
  user: User;
}

type UserInfo = Omit<User, "id" | "orders" | "role">;
