import React, { useEffect, useState } from "react";
import { API } from "services/api";
import { User } from "types";

type ContextType = {
  isLoaded: boolean;
  session: User | null;
  discardSession: () => void;
};

const defaultContext: ContextType = {
  isLoaded: false,
  session: null,
  discardSession: () => {},
};
export const SessionContext = React.createContext(defaultContext);

export function AuthWrapper({ children }: { children: React.ReactNode }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [session, setSession] = useState<User | null>(null);
  useEffect(() => {
    API.auth.me().then((response) => {
      setIsLoaded(true);
      setSession(response.user);
    });
  }, []);

  function discardSession() {
    setSession(null);
  }

  const actualContext: ContextType = { isLoaded, session, discardSession };

  return (
    <SessionContext.Provider value={actualContext}>
      {children}
    </SessionContext.Provider>
  );
}
