import React from "react";

import { useHomePageInteractor } from "./interactor";
import { HomePageRouter } from "./router";

export interface IProps {}
export const HomePage: React.FC<IProps> = () => {
  const interactor = useHomePageInteractor();
  return <HomePageRouter interactor={interactor} />;
};
