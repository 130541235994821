import { Button } from "@mui/material";

export function SmallButton({
  children,
  margin,
  onClick,
}: {
  children: string;
  margin: string;
  onClick: () => void;
}) {
  return (
    <Button
      variant="contained"
      color="primary"
      style={{
        height: "40px",
        borderRadius: 0,
        fontSize: "16px",
        lineHeight: "16px",
        fontWeight: "600",
        textTransform: "none",
        margin: margin,
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}
