import React from "react";

import { useMyOrdersPageInteractor } from "./interactor";
import { MyOrdersPageRouter } from "./router";

export interface IProps {}
export const MyOrdersPage: React.FC<IProps> = () => {
  const interactor = useMyOrdersPageInteractor();
  return <MyOrdersPageRouter interactor={interactor} />;
};
