import styles from "./team.module.css";
import { ReactComponent as Facebook } from "assets/facebook.svg";
import { ReactComponent as Twitter } from "assets/twitter.svg";
import { ReactComponent as Linkedin } from "assets/linkedin.svg";
import { ReactComponent as Gplus } from "assets/gplus.svg";
import { IHomePageInteractor } from "../..//interactor";

interface IProps {
  interactor: IHomePageInteractor;
}
export const Team: React.FC<IProps> = ({ interactor }) => {
  return (
    <div className={styles.team}>
      <h1>{interactor.team.header}</h1>
      <p className={styles.teamHeaderText}>{interactor.team.description}</p>
      <div className={styles.teamMembers}>
        {interactor.team.members.map((member) => {
          return (
            <div key={member.name} className={styles.teamMemberProfile}>
              <img src={member.photo} alt={member.name} />
              <h2>{member.name}</h2>
              <p>{member.title}</p>
              <a href={`mailto:${member.contacts.email}`}>
                {member.contacts.email}
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};
