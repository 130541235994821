import designAndEngineering from "assets/designAndEngineering.jpg";
import systemIntegrationServices from "assets/systemIntegrationServices.jpg";

export interface IServicesPageInteractor {
  pageName: string;
  header: string;
  message: string;
  services: { name: string; img: string; description: string }[];
}

export const useServicesPageInteractor = (): IServicesPageInteractor => {
  return {
    pageName: "Services",
    header: "Our Services",
    message: "Atlas can help with designing your custom application",
    services: [
      {
        name: "Design and Engineering",
        img: designAndEngineering,
        description:
          "Atlas can help with designing your custom application. We have an excellent team on board to assist you with it. Our design and engineering services are customized to suit the needs of each client. We can turn your idea into a reality by developing a functioning 3-D model. Our manufacturing experts will then choose the ideal process to bring your idea into reality. Our fabrication, design, and engineering services are all done in-house, offering you a load of flexibility and reliability. So, knock on our door for your design and engineering requirements to be attended to.",
      },
      {
        name: "System Integration Services",
        img: systemIntegrationServices,
        description:
          "We are currently living in a world that is driven by technology. Technology is advancing with each passing day, and it is challenging for the industrial market to keep up with it. However, technology has brought its benefits to the industrial sector. The design, development, and manufacture of ground-breaking power products for motor control applications has been made possible with technical advancements. Such products help us to drive solutions that provide substantial value to clients in the industrial sector. Does your organization need a system upgrade? Do you have to integrate new systems and components with already existing ones? Then, we have your back. We offer the best industrial solutions to various organizations and will be delighted to assist you in the step-by-step process of system integration. What makes us and our services unique? We have on board a distinct set of skills that range from the design and manufacture of critical motor control components to the development and integration of entire systems. We craft solutions that will be able to perform in extreme environments and utilize the latest technology.",
      },
    ],
  };
};
