// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".team_team__Jzmfz {\n  padding: 0 20%;\n  text-align: center;\n}\n.team_teamHeaderText__XWXJ9 {\n  padding: 10px 30%;\n}\n.team_teamMembers__q4eca {\n  padding: 50px 0 40px;\n  display: flex;\n  gap: 30px;\n  justify-content: center;\n}\n.team_teamMemberProfile__TPh77 {\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  padding-bottom: 15px;\n}\n.team_teamMemberProfile__TPh77 > h2 {\n  padding: 20px 0 10px;\n}\n\n.team_teamMemberProfile__TPh77 > p {\n  font-weight: 300;\n}\n\n@media (max-width: 1280px) {\n  .team_teamMembers__q4eca {\n    flex-wrap: wrap;\n  }\n  .team_teamHeaderText__XWXJ9 {\n    padding: 10px 0;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/home-page/components/team/team.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,oBAAoB;EACpB,aAAa;EACb,SAAS;EACT,uBAAuB;AACzB;AACA;EACE,oCAAoC;EACpC,oBAAoB;AACtB;AACA;EACE,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":[".team {\n  padding: 0 20%;\n  text-align: center;\n}\n.teamHeaderText {\n  padding: 10px 30%;\n}\n.teamMembers {\n  padding: 50px 0 40px;\n  display: flex;\n  gap: 30px;\n  justify-content: center;\n}\n.teamMemberProfile {\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  padding-bottom: 15px;\n}\n.teamMemberProfile > h2 {\n  padding: 20px 0 10px;\n}\n\n.teamMemberProfile > p {\n  font-weight: 300;\n}\n\n@media (max-width: 1280px) {\n  .teamMembers {\n    flex-wrap: wrap;\n  }\n  .teamHeaderText {\n    padding: 10px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"team": "team_team__Jzmfz",
	"teamHeaderText": "team_teamHeaderText__XWXJ9",
	"teamMembers": "team_teamMembers__q4eca",
	"teamMemberProfile": "team_teamMemberProfile__TPh77"
};
export default ___CSS_LOADER_EXPORT___;
