import { useNavigate } from "react-router-dom";
import { ReactComponent as PowerCoordinationStudies } from "./assets/powerCoordinationStudies.svg";
import { ReactComponent as HMIProgramming } from "./assets/hmiProgramming.svg";
import { ReactComponent as MotorControlCenter } from "./assets/motorControlCenter.svg";
import { ReactComponent as PLCProgramming } from "./assets/plcProgramming.svg";
import { ReactComponent as ThreeDModeling } from "./assets/3dModeling.svg";

import designAndEngineering from "assets/designAndEngineering.jpg";
import systemIntegrationServices from "assets/systemIntegrationServices.jpg";
import { employees } from "constants/employees";

export interface IHomePageInteractor {
  pageName: string;
  navigate: (address: string) => void;
  aboutCompany: {
    header: { name: string; slogan: string; buttonText: string };
    content: {
      texts: { first: string; second: string };
      contactButtonText: string;
    };
  };
  services: {
    customBusbar: string;
    header: {
      name: string;
      svg: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & { title?: string }
      >;
    }[];
    contentHeader: string;
    content: { img: string; name: string; text: string }[];
  };
  team: {
    header: string;
    description: string;
    members: {
      photo: string;
      name: string;
      title: string;
      contacts: {
        email: string;
        facebook: string;
        twitter: string;
        linkedin: string;
        gplus: string;
      };
    }[];
  };
}

export const useHomePageInteractor = (): IHomePageInteractor => {
  const navigate = useNavigate();

  return {
    pageName: "Home",
    navigate: navigate,
    aboutCompany: {
      header: {
        name: "Atlas Power Products",
        slogan: "Your Power Distribution Source",
        buttonText: "Explore now",
      },
      content: {
        texts: {
          first:
            "Atlas Power Products is a full-service fabrication and integration facility with the ability to design and construct custom UL 891 switchboards whether for single application products or developing standard OEM configurations for rapid deployment. Our LV switchgear units are built utilizing power and control components from avariety of industry-leading manufacturers.",
          second:
            "Atlas switchboards are designed in house by our professional engineers. Atlas switchboards are designed to meet the safety and quality of UL standards. All components are fabricated locally which means shorter lead times and endless capabilities of switchboard customization.",
        },
        contactButtonText: "Contact us",
      },
    },
    services: {
      customBusbar: "Custom Busbar",
      header: [
        { name: "Power Coordination Studies", svg: PowerCoordinationStudies },
        { name: "HMI Programming", svg: HMIProgramming },
        { name: "Motor Control Center", svg: MotorControlCenter },
        { name: "PLC Programming", svg: PLCProgramming },
        { name: "3D Modeling", svg: ThreeDModeling },
      ],
      contentHeader: "Our Services",
      content: [
        {
          img: designAndEngineering,
          name: "Design and Engineering",
          text: "Atlas can help with designing your custom application. We have an excellent team on board to assist you with it. Our design and engineering services are customized to suit the needs of each client. We can turn your idea into a reality by developing a functioning 3-D model. Our manufacturing experts will then choose the ideal process to bring your idea into reality. Our fabrication, design, and engineering services are all done in-house, offering you a load of flexibility and reliability. So, knock on our door for your design and engineering requirements to be attended to.",
        },
        {
          img: systemIntegrationServices,
          name: "System Integration Services",
          text: "We are currently living in a world that is driven by technology. Technology is advancing with each passing day, and it is challenging for the industrial market to keep up with it. However, technology has brought its benefits to the industrial sector. The design, development, and manufacture of ground-breaking power products for motor control applications has been made possible with technical advancements. Such products help us to drive solutions that provide substantial value to clients in the industrial sector.",
        },
      ],
    },
    team: {
      header: "Our Team",
      description:
        "We offer the best industrial solutions to various organizations and will be delighted to assist you in the step-by-step process of system integration.",
      members: employees,
    },
  };
};
