import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#ffc107",
      light: "#ffc107",
      dark: "#ffc107",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#212121",
      light: "#757575",
      dark: "#000000",
      contrastText: "#FFFFFF",
    },
    error: { main: "#d32f2f", contrastText: "#d32f2f" },
  },
  typography: { fontSize: 18 },
});
