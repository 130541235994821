// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pass-restore-page_restorationForm__I9n0z {\n  padding: 70px 0;\n  display: grid;\n  grid-template-columns: 1fr 300px 1fr;\n  gap: 20px;\n}\n.pass-restore-page_restorationForm__I9n0z > * {\n  grid-column: 2;\n}\n.pass-restore-page_restorationInput__s-wyp {\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  padding: 10px;\n  font-size: 15px;\n  text-align: center;\n}\n.pass-restore-page_buttonSection__QfUzU {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.pass-restore-page_message__K3zqc {\n  width: 100%;\n  font-size: 12px;\n  position: relative;\n  top: -15px;\n}\n.pass-restore-page_success__7o5lN {\n  color: rgb(1, 182, 1);\n}\n.pass-restore-page_failure__eWxnl {\n  color: red;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/pass-restore-page/index.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,oCAAoC;EACpC,SAAS;AACX;AACA;EACE,cAAc;AAChB;AACA;EACE,oCAAoC;EACpC,aAAa;EACb,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,UAAU;AACZ;AACA;EACE,qBAAqB;AACvB;AACA;EACE,UAAU;AACZ","sourcesContent":[".restorationForm {\n  padding: 70px 0;\n  display: grid;\n  grid-template-columns: 1fr 300px 1fr;\n  gap: 20px;\n}\n.restorationForm > * {\n  grid-column: 2;\n}\n.restorationInput {\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  padding: 10px;\n  font-size: 15px;\n  text-align: center;\n}\n.buttonSection {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.message {\n  width: 100%;\n  font-size: 12px;\n  position: relative;\n  top: -15px;\n}\n.success {\n  color: rgb(1, 182, 1);\n}\n.failure {\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"restorationForm": "pass-restore-page_restorationForm__I9n0z",
	"restorationInput": "pass-restore-page_restorationInput__s-wyp",
	"buttonSection": "pass-restore-page_buttonSection__QfUzU",
	"message": "pass-restore-page_message__K3zqc",
	"success": "pass-restore-page_success__7o5lN",
	"failure": "pass-restore-page_failure__eWxnl"
};
export default ___CSS_LOADER_EXPORT___;
