import React from "react";

import { useSingleProductPageInteractor } from "./interactor";
import { SingleProductPageRouter } from "./router";

export interface IProps {}
export const SingleProductPage: React.FC<IProps> = () => {
  const interactor = useSingleProductPageInteractor();
  return <SingleProductPageRouter interactor={interactor} />;
};
