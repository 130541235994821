// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".userInfo_userInfoPage__CQi3m {\n  padding: 70px 20% 0;\n}\n.userInfo_pageHeader__QOGD9 {\n  text-align: center;\n}\n.userInfo_userInfo__hKzF5 {\n  padding-top: 50px;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  gap: 20px;\n}\n.userInfo_userInfo__hKzF5 > div {\n  text-align: center;\n  width: 45%;\n}\n.userInfo_emailInput__JTulN {\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  margin: 10px 0;\n  padding: 10px;\n  font-size: 15px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/user-profile-page/components/userInfo/userInfo.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,aAAa;EACb,eAAe;EACf,8BAA8B;EAC9B,SAAS;AACX;AACA;EACE,kBAAkB;EAClB,UAAU;AACZ;AACA;EACE,oCAAoC;EACpC,cAAc;EACd,aAAa;EACb,eAAe;AACjB","sourcesContent":[".userInfoPage {\n  padding: 70px 20% 0;\n}\n.pageHeader {\n  text-align: center;\n}\n.userInfo {\n  padding-top: 50px;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  gap: 20px;\n}\n.userInfo > div {\n  text-align: center;\n  width: 45%;\n}\n.emailInput {\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  margin: 10px 0;\n  padding: 10px;\n  font-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userInfoPage": "userInfo_userInfoPage__CQi3m",
	"pageHeader": "userInfo_pageHeader__QOGD9",
	"userInfo": "userInfo_userInfo__hKzF5",
	"emailInput": "userInfo_emailInput__JTulN"
};
export default ___CSS_LOADER_EXPORT___;
