import { SessionContext } from "components/authWrapper";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "services/api";
import { Order, OrderStatus } from "types";

export interface IAllOrdersInteractor {
  pageName: string;
  searchBy: { order_id: string; user: string };
  ordersHeaders: string[];
  orderStatuses: OrderStatus[];
  isAdmin: boolean;
  orders: Order[];
  orderId: string;
  setOrderId: (id: string) => void;
  ownerId: string;
  setOwnerId: (id: string) => void;
  getAllOrders: () => void;
  updateOrderStatus: (id: string, status: any) => void;
}

export const useAllOrdersInteractor = (): IAllOrdersInteractor => {
  const { isLoaded, session } = React.useContext(SessionContext);
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);

  const [ordersData, setOrdersData] = useState<Order[]>([]);
  const [orderId, setOrderId] = useState("");
  const [ownerId, setOwnerId] = useState("");

  function getAllOrders() {
    API.order.getAllOrders().then((response) => {
      setOrdersData(response);
    });
  }

  function updateOrderStatus(id: string, status: any) {
    API.order.updateOrderStatus(id, status).then(() => getAllOrders());
  }

  useEffect(() => {
    getAllOrders();
  }, []);

  const orders = ordersData
    .sort((a, b) => {
      return Date.parse(b.updated_at) - Date.parse(a.updated_at);
    })
    .filter((order) => order.id.includes(orderId))
    .filter((order) => order.owner.full_name.toLowerCase().includes(ownerId));

  useEffect(() => {
    if (session?.role === "ADMIN") {
      setIsAdmin(true);
      return;
    }
    isLoaded && navigate("/");
  }, [isLoaded]);

  return {
    pageName: "All Orders",
    searchBy: { order_id: "Search by order id:", user: "Search by user:" },
    ordersHeaders: [
      "Order id",
      "Placed by",
      "Order status",
      "Created at",
      "Last updated",
    ],
    orderStatuses: [
      OrderStatus.DRAFT,
      OrderStatus.IN_PROGRESS,
      OrderStatus.DELIVERING,
      OrderStatus.COMPLETED,
    ],
    isAdmin: isAdmin,
    orders: orders,
    orderId: orderId,
    setOrderId: setOrderId,
    ownerId: ownerId,
    setOwnerId: setOwnerId,
    getAllOrders: getAllOrders,
    updateOrderStatus: updateOrderStatus,
  };
};
