import { DefaultPageLayout } from "components/page-layout";
import React from "react";
import { Link } from "react-router-dom";
import { Product } from "types";
import styles from "./index.module.css";

import { IProductsPageInteractor } from "./interactor";

export interface IProps {
  interactor: IProductsPageInteractor;
}

interface ProductProps {
  product: Product;
}
export const ProductsPageRouter: React.FC<IProps> = ({ interactor }) => {
  return (
    <DefaultPageLayout pageName={interactor.pageName}>
      <Products interactor={interactor} />
    </DefaultPageLayout>
  );
};

const Products: React.FC<IProps> = ({ interactor }) => {
  return (
    <div className={styles.products}>
      {interactor.products.map((product) => {
        return <OneProduct key={product.id} product={product} />;
      })}
    </div>
  );
};

const OneProduct: React.FC<ProductProps> = ({ product }) => {
  return (
    <div className={styles.product} key={product.name}>
      <Link to={product.id}>
        <img src={product.images[0]} alt={product.name} width="100%" />
        <p>
          <b>{product.name}</b>
        </p>
      </Link>
    </div>
  );
};
