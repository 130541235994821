import { AbstractEndpoint } from "./abstract-endpoint";

export class SharedEndpoint extends AbstractEndpoint {
  constructor() {
    super("shared");
  }

  public async sendMessage({
    name,
    email,
    message,
  }: {
    name: string;
    email: string;
    message: string;
  }) {
    const data = { name, email, message };
    try {
      await this.post("contact-us", data);
      return true;
    } catch (e: any) {
      throw new Error(e.message);
    }
  }
}
