import React from "react";
import styles from "./index.module.css";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import { IContactPageInteractor } from "../../interactor";

interface IProps {
  interactor: IContactPageInteractor;
}
export const Map: React.FC<IProps> = ({ interactor }) => {
  if (!interactor.map.isLoaded) {
    return <p>{interactor.map.loading}</p>;
  }

  return (
    <div className={styles.mapContainer}>
      <GoogleMap
        center={interactor.map.center}
        zoom={interactor.map.zoom}
        mapContainerStyle={{ width: "100%", height: "100%" }}
        options={{
          streetViewControl: interactor.map.streetViewControl,
          mapTypeControl: interactor.map.mapTypeControl,
        }}
      >
        <MarkerF position={interactor.map.center} />
      </GoogleMap>
    </div>
  );
};
