import { SessionContext } from "components/authWrapper";
import React, { useEffect, useState } from "react";
import { Order, User } from "types";

export interface IMyOrdersPageInteractor {
  pageName: string;
  session: User | null;
  orders: Order[] | null;
  noOrders: string;
  ordersHeaders: string[];
  orderConfiguration: {
    job_name: string;
    panel_name: string;
    special_requirements: string;
    box: string;
    mtc: string;
    switch: string;
    busAmpacity: string;
    voltage: string;
    feed: string;
    mainBoxes: string;
    editButtonText: string;
  };
}

export const useMyOrdersPageInteractor = (): IMyOrdersPageInteractor => {
  const { session } = React.useContext(SessionContext);
  const [orders, setOrders] = useState<Order[] | null>(null);

  useEffect(() => {
    if (session) {
      let receivedOrders: Order[] = JSON.parse(JSON.stringify(session.orders));
      setOrders(
        receivedOrders.sort(
          (a, b) => Date.parse(b.updated_at) - Date.parse(a.updated_at)
        )
      );
    }
  }, [session]);

  return {
    pageName: "My orders",
    session: session,
    orders: orders,
    noOrders: "No orders found",
    ordersHeaders: [
      "Order id",
      "Order status",
      "Order info",
      "Created at",
      "Last updated",
    ],
    orderConfiguration: {
      job_name: "Job name: ",
      panel_name: "Panel name: ",
      special_requirements: "Special requirements: ",
      box: "Box size: ",
      mtc: "Metering transformer cabinet: ",
      switch: "Main switch: ",
      busAmpacity: "Bus ampacity: ",
      voltage: "Voltage: ",
      feed: "Feed: ",
      mainBoxes: "Main boxes: ",
      editButtonText: "Edit",
    },
  };
};
