import React, { useState } from "react";
import { API } from "services/api";
import { useJsApiLoader } from "@react-google-maps/api";
import { companyEmails } from "constants/emails";
import { companyAddresses } from "constants/addresses";
import { companyNumbers } from "constants/numbers";

export interface IContactPageInteractor {
  pageName: string;
  contactInfo: {
    header: string;
    address: string;
    phoneNumber: string;
    emails: { label: string; email: string }[];
  };
  contactForm: {
    header: string;
    onSendMessage: React.FormEventHandler;
    name: {
      placeholder: string;
      value: string;
      onChange: React.ChangeEventHandler<HTMLInputElement>;
    };
    email: {
      placeholder: string;
      value: string;
      onChange: React.ChangeEventHandler<HTMLInputElement>;
    };
    message: {
      placeholder: string;
      value: string;
      onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
    };
    sendButtonText: string;
  };
  map: {
    loading: string;
    center: { lat: number; lng: number };
    streetViewControl: boolean;
    mapTypeControl: boolean;
    zoom: number;
    isLoaded: boolean;
  };
}

export const useContactPageInteractor = (): IContactPageInteractor => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const handleSendMessage: React.FormEventHandler = React.useCallback((e) => {
    e.preventDefault();
    void API.shared.sendMessage({ name, email, message });
  }, []);

  return {
    pageName: "Contact us",
    contactInfo: {
      header: "Contact Information",
      address: companyAddresses.HEAD_OF_OFFICE.address,
      phoneNumber: companyNumbers[0].number,
      emails: companyEmails,
    },
    contactForm: {
      header: "Leave your message",
      onSendMessage: handleSendMessage,
      name: {
        placeholder: "Your name",
        value: name,
        onChange: (e) => {
          setName(e.target.value);
        },
      },
      email: {
        placeholder: "Your email",
        value: email,
        onChange: (e) => {
          setEmail(e.target.value);
        },
      },
      message: {
        placeholder: "Your message",
        value: message,
        onChange: (e) => {
          setMessage(e.target.value);
        },
      },
      sendButtonText: "Send Message",
    },
    map: {
      loading: "The map is loading...",
      center: { lat: 42.58731, lng: -83.05225 },
      streetViewControl: false,
      mapTypeControl: false,
      zoom: 10,
      isLoaded,
    },
  };
};
