import styles from "./productLayout.module.css";
import { ClassicButton } from "mui/classicButton";
import { IProps } from "pages/single-product-page/router";

export const ProductLayout: React.FC<IProps> = ({ interactor }) => {
  if (!interactor.product) {
    return null;
  }

  return (
    <div className={styles.productLayout}>
      <div className={styles.productPictures}>
        <img
          className={styles.activeImage}
          src={interactor.product.images[interactor.layout.activeImage]}
          alt={`${interactor.product.name}`}
          height="100%"
        />
        <div className={styles.inactiveImageBar}>
          {interactor.product.images
            .filter((img, index) => index !== interactor.layout.activeImage)
            .map((img, index) => {
              return (
                <img
                  key={img}
                  className={styles.inActiveImage}
                  src={img}
                  alt={`${index}`}
                  width="100%"
                  height="100%"
                  onClick={() =>
                    interactor.product &&
                    interactor.layout.setActiveImage(
                      interactor.product.images.findIndex(
                        (image) => image === img
                      )
                    )
                  }
                />
              );
            })}
        </div>
      </div>
      <div className={styles.productInfo}>
        <h2>{interactor.product.name}</h2>
        <p className={styles.shortDescription}>
          {interactor.product.shortDescription}
        </p>
        <ClassicButton
          width="150px"
          margin="0"
          onClick={interactor.layout.contactButton.handleNavigateToContact}
        >
          {interactor.layout.contactButton.text}
        </ClassicButton>
      </div>
    </div>
  );
};
