import { ClassicButton } from "mui/classicButton";
import styles from "./aboutCompany.module.css";
import aboutCompanyFirst from "../../assets/aboutCompanyFirst.jpg";
import aboutCompanySecond from "../../assets/aboutCompanySecond.jpg";
import { IHomePageInteractor } from "../..//interactor";

interface IProps {
  interactor: IHomePageInteractor;
}

export const AboutCompany: React.FC<IProps> = ({ interactor }) => {
  return (
    <>
      <div className={styles.aboutCompanyHeader}>
        <p>{interactor.aboutCompany.header.name}</p>
        <h1>{interactor.aboutCompany.header.slogan}</h1>
        <ClassicButton
          width="170px"
          margin="0"
          onClick={() => {
            interactor.navigate("/products");
          }}
        >
          {interactor.aboutCompany.header.buttonText}
        </ClassicButton>
      </div>
      <div className={styles.aboutCompanyContent}>
        <div>
          <p className={styles.firstAboutText}>
            {interactor.aboutCompany.content.texts.first}
          </p>
          <img src={aboutCompanyFirst} alt="first" width="100%" />
        </div>
        <div>
          <img
            src={aboutCompanySecond}
            alt="second"
            width="100%"
            height="320px"
          />
          <p className={styles.secondAboutText}>
            {interactor.aboutCompany.content.texts.second}
          </p>
        </div>
        <ClassicButton
          width="150px"
          margin="20px 10px 0 auto"
          onClick={() => {
            interactor.navigate("/contact-us");
          }}
        >
          {interactor.aboutCompany.content.contactButtonText}
        </ClassicButton>
      </div>
    </>
  );
};
