import { IProps } from "pages/single-product-page/router";
import styles from "./description.module.css";

export const Description: React.FC<IProps> = ({ interactor }) => {
  if (!interactor.product) return null;

  return (
    <>
      <p>{interactor.product.description}</p>
      <div className={styles.features}>
        <p className={styles.listName}>{interactor.description.features}</p>
        <ul>
          {interactor.product.features.map((feature) => {
            return (
              <li key={feature} className={styles.listItem}>
                {feature}
              </li>
            );
          })}
        </ul>
        <p className={styles.listName}>{interactor.description.options}</p>
        <ul>
          {interactor.product.options.map((option) => {
            return (
              <li key={option} className={styles.listItem}>
                {option}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};
