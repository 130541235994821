import george from "assets/George.jpg";
import fadi from "assets/Fadi.jpg";
import yousif from "assets/Yousif.jpg";

export const employees = [
  {
    photo: george,
    name: "George Hanna-Kachl",
    title: "Owner \\ Founder",
    contacts: {
      email: "george@atlaspowerproducts.com",
      facebook: "https://facebook.com",
      twitter: "https://twitter.com",
      linkedin: "https://linkedin.com",
      gplus: "https://google.com",
    },
  },
  {
    photo: fadi,
    name: "Fadi Hanna-Kachl",
    title: "LV Switchboard Sales",
    contacts: {
      email: "fadi@atlaspowerproducts.com",
      facebook: "https://facebook.com",
      twitter: "https://twitter.com",
      linkedin: "https://linkedin.com",
      gplus: "https://google.com",
    },
  },
  {
    photo: yousif,
    name: "Yousif Hanna-Kachl",
    title: "ICP Sales",
    contacts: {
      email: "yousif@atlaspowerproducts.com",
      facebook: "https://facebook.com",
      twitter: "https://twitter.com",
      linkedin: "https://linkedin.com",
      gplus: "https://google.com",
    },
  },
];
