import { products } from "resources/products";
import { Product } from "types";

export interface IProductsPageInteractor {
  pageName: string;
  products: Product[];
}

export const useProductsPageInteractor = (): IProductsPageInteractor => {
  return {
    pageName: "Products",
    products: products,
  };
};
