import { IProps } from "pages/user-profile-page/router";
import { Link } from "react-router-dom";
import { LinkButton } from "mui/linkButton";
import styles from "./orders.module.css";

export const OrdersInfo: React.FC<IProps> = ({ interactor }) => {
  if (interactor.orders.length === 0) {
    return (
      <h1 className={styles.noOrdersMessage}>
        {interactor.ordersSection.noOrders}
      </h1>
    );
  }

  return (
    <div className={styles.pageContent}>
      <h2 className={styles.pageHeader}>{interactor.ordersSection.header}</h2>
      <div className={styles.ordersHeader}>
        {interactor.ordersSection.ordersHeaders.map((header) => (
          <p key={header}>{header}</p>
        ))}
      </div>
      {interactor.orders.map((order) => {
        const config = JSON.parse(atob(order.configuration));
        return (
          <div key={order.id} className={styles.orderField}>
            <p>{order.id}</p>
            <p>{order.status}</p>
            <div>
              <p>
                {interactor.ordersSection.orderConfiguration.box}
                {config.box_size}
              </p>
              <p>
                {interactor.ordersSection.orderConfiguration.mtc}
                {config.metering_transformer_cabinet ? "Yes" : "No"}
              </p>
              <p>
                {interactor.ordersSection.orderConfiguration.switch}
                {config.main_switch}
              </p>
              <p>
                {interactor.ordersSection.orderConfiguration.busAmpacity}
                {config.busAmpacity}
              </p>
              <p>
                {interactor.ordersSection.orderConfiguration.voltage}
                {config.voltage}
              </p>
              <p>
                {interactor.ordersSection.orderConfiguration.feed}
                {config.feed}
              </p>
              <p>
                {interactor.ordersSection.orderConfiguration.mainBoxes}
                {config.main_box_quantity}
              </p>
              {order.status === "DRAFT" && (
                <Link to={`/orders/${order.id}/edit`}>
                  <LinkButton>
                    {interactor.ordersSection.orderConfiguration.editButtonText}
                  </LinkButton>
                </Link>
              )}
            </div>
            <p>{order.create_at.slice(0, -8).replace("T", " ")}</p>
            <p>{order.updated_at.slice(0, -8).replace("T", " ")}</p>
          </div>
        );
      })}
    </div>
  );
};
