import styles from "./related.module.css";
import { Link } from "react-router-dom";
import { IProps } from "pages/single-product-page/router";

export const Related: React.FC<IProps> = ({ interactor }) => {
  if (!interactor.related.related) return null;

  return (
    <div>
      <h3 className={styles.relatedHeader}>{interactor.related.header}</h3>
      <div className={styles.relatedProducts}>
        {interactor.related.related.map((product) => {
          return (
            <div className={styles.product} key={product.name}>
              <Link to={`/products/${product.id}`}>
                <img src={product.images[0]} alt={product.name} width="100%" />
                <p>
                  <b>{product.name}</b>
                </p>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};
