import { Button } from "@mui/material";

export function LinkButton({ children }: { children: string }) {
  return (
    <Button
      variant="contained"
      color="primary"
      style={{
        height: "40px",
        borderRadius: 0,
        fontSize: "16px",
        lineHeight: "16px",
        fontWeight: "600",
        textTransform: "none",
        position: "absolute",
        right: "10px",
        bottom: "10px",
      }}
    >
      {children}
    </Button>
  );
}
