import React from "react";

import { useServicesPageInteractor } from "./interactor";
import { ServicesPageRouter } from "./router";

export interface IProps {}
export const ServicesPage: React.FC<IProps> = () => {
  const interactor = useServicesPageInteractor();
  return <ServicesPageRouter interactor={interactor} />;
};
