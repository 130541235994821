import React from "react";

import { useClientsPageInteractor } from "./interactor";
import { ClientsPageRouter } from "./router";

export interface IProps {}
export const ClientsPage: React.FC<IProps> = () => {
  const interactor = useClientsPageInteractor();
  return <ClientsPageRouter interactor={interactor} />;
};
