import { HomePage } from "pages/home-page";
import { ServicesPage } from "pages/services-page";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ProductsPage } from "pages/products-page";
import { SingleProductPage } from "pages/single-product-page";
import { LoginPage } from "pages/login-page";
import { PassRestorePage } from "pages/pass-restore-page";
import { useEffect } from "react";
import { UserProfilePage } from "pages/user-profile-page";
import { SingleOrderPage } from "pages/single-order-page";
import { ContactPage } from "./pages/contact-page";
import { AllOrdersPage } from "pages/all-orders-page";
import { ClientsPage } from "pages/clients-page";
import { CreateAccountPage } from "pages/create-account-page";
import { CreateOrderPage } from "pages/create-order-page";
import { EditOrderPage } from "pages/edit-order-page";
import { MyOrdersPage } from "pages/my-orders-page";

function App() {
  const location = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="services" element={<ServicesPage />} />
      <Route path="products" element={<ProductsPage />} />
      <Route path="products/:productId" element={<SingleProductPage />} />
      <Route path="contact-us" element={<ContactPage />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="restore" element={<PassRestorePage />} />
      <Route path="clients/create" element={<CreateAccountPage />} />
      <Route path="clients" element={<ClientsPage />} />
      <Route path="clients/:userId" element={<UserProfilePage />} />
      <Route path="orders/:orderId" element={<SingleOrderPage />} />
      <Route path="orders/:orderId/edit" element={<EditOrderPage />} />
      <Route path="orders/create" element={<CreateOrderPage />} />
      <Route path="orders" element={<MyOrdersPage />} />
      <Route path="orders/all" element={<AllOrdersPage />} />
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
