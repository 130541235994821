import React from "react";

import { usePassRestorePageInteractor } from "./interactor";
import { PassRestorePageRouter } from "./router";

export interface IProps {}
export const PassRestorePage: React.FC<IProps> = () => {
  const interactor = usePassRestorePageInteractor();
  return <PassRestorePageRouter interactor={interactor} />;
};
