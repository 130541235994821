import { DefaultPageLayout } from "components/page-layout";
import React from "react";
import styles from "./index.module.css";

import { IServicesPageInteractor } from "./interactor";

export interface IProps {
  interactor: IServicesPageInteractor;
}
export const ServicesPageRouter: React.FC<IProps> = ({ interactor }) => {
  return (
    <DefaultPageLayout pageName={interactor.pageName}>
      <div className={styles.servicesContent}>
        <h1>{interactor.header}</h1>
        <p>{interactor.message}</p>
        <div className={styles.servicesDescription}>
          {interactor.services.map((service) => {
            return (
              <div key={service.name}>
                <img src={service.img} alt={service.name} width="100%" />
                <h2 className={styles.serviceDescriptionHeader}>
                  {service.name}
                </h2>
                <p className={styles.serviceDescriptionContent}>
                  {service.description}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </DefaultPageLayout>
  );
};
