export class APIBasicError extends Error {
  /**
   * Status code of error
   *
   * @private
   */
  private readonly _statusCode: number;

  /**
   * i18n key for error message
   *
   * @private
   */
  private readonly _message: string;

  /**
   * HTTP error name
   * Ex: Not Found
   *
   * @private
   */
  private readonly _error: string;

  constructor(statusCode: number, message: string, error: string) {
    super();
    this._statusCode = statusCode;
    this._message = message;
    this._error = error;
  }

  get statusCode(): number {
    return this._statusCode;
  }

  get message(): string {
    return this._message;
  }

  get error(): string {
    return this._error;
  }
}
