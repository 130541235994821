import { DefaultPageLayout } from "components/page-layout";
import React from "react";
import { Oval } from "react-loader-spinner";
import { OrdersInfo } from "./components/orders";
import { UserInfo } from "./components/userInfo";

import { IUserProfilePageInteractor } from "./interactor";

export interface IProps {
  interactor: IUserProfilePageInteractor;
}
export const UserProfilePageRouter: React.FC<IProps> = ({ interactor }) => {
  return (
    <DefaultPageLayout pageName={interactor.pageName}>
      {!interactor.isAdmin || !interactor.user ? (
        <div
          style={{
            display: "grid",
            justifyContent: "center",
            padding: "20vh 0 20vh ",
          }}
        >
          <Oval color="#f5f5f5" secondaryColor="#343434" />
        </div>
      ) : (
        <>
          <UserInfo interactor={interactor} />
          <OrdersInfo interactor={interactor} />;
        </>
      )}
    </DefaultPageLayout>
  );
};
