import { useEffect } from "react";
import { HookEvent } from "types";

export const useMessageListener = (callback: (data: string) => void) => {
  useEffect(() => {
    const process = (event: HookEvent) => {
      if (event.origin === "https://deployed.dynamaker.com" && callback) {
        callback(event.data);
      }
    };
    window.addEventListener("message", process);
    return () => window.removeEventListener("message", process);
  }, [callback]);

  return {};
};
