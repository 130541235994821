// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".contact-page_contactSection__DByPP {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 20px;\n  justify-content: space-between;\n  padding: 40px 15% 100px;\n}\n\n@media (max-width: 1280px) {\n  .contact-page_contactSection__DByPP {\n    justify-content: center;\n    gap: 40px;\n    padding: 40px 10% 100px;\n  }\n  .contact-page_contactSection__DByPP > div {\n    width: 100%;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/contact-page/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,8BAA8B;EAC9B,uBAAuB;AACzB;;AAEA;EACE;IACE,uBAAuB;IACvB,SAAS;IACT,uBAAuB;EACzB;EACA;IACE,WAAW;EACb;AACF","sourcesContent":[".contactSection {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 20px;\n  justify-content: space-between;\n  padding: 40px 15% 100px;\n}\n\n@media (max-width: 1280px) {\n  .contactSection {\n    justify-content: center;\n    gap: 40px;\n    padding: 40px 10% 100px;\n  }\n  .contactSection > div {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contactSection": "contact-page_contactSection__DByPP"
};
export default ___CSS_LOADER_EXPORT___;
