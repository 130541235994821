import { DefaultPageLayout } from "components/page-layout";
import React from "react";
import { Oval } from "react-loader-spinner";
import styles from "./index.module.css";
import { QuotationModal } from "mui/modal";

import { ICreateOrderPageInteractor } from "./interactor";
import { SubmitButton } from "mui/submitButton";
import { CancelButton } from "mui/cancelButton";

export interface IProps {
  interactor: ICreateOrderPageInteractor;
}

export const CreateOrderPageRouter: React.FC<IProps> = ({ interactor }) => {
  if (!interactor.isLoaded) {
    return (
      <DefaultPageLayout pageName={interactor.pageName}>
        <div
          style={{
            display: "grid",
            justifyContent: "center",
            padding: "20vh 0 20vh ",
          }}
        >
          <Oval color="#f5f5f5" secondaryColor="#343434" />
        </div>
      </DefaultPageLayout>
    );
  }
  if (!interactor.isAuth)
    return (
      <DefaultPageLayout pageName={interactor.pageName}>
        <h1 className={styles.unauthorized}>{interactor.authorizeMessage}</h1>
      </DefaultPageLayout>
    );
  return (
    <DefaultPageLayout pageName={interactor.pageName}>
      <div className={styles.createOrderPage}>
        <QuotationModal
          open={interactor.isModalOpen}
          handleClose={interactor.handleCloseModal}
        >
          <form
            className={styles.quotationForm}
            onSubmit={(e) => {
              e.preventDefault();
              interactor.handleSubmitModal();
            }}
          >
            <p>{interactor.formLabels.jobName}</p>
            <input
              className={styles.quotationInput}
              required
              value={interactor.formValues.jobName}
              onChange={(e) => interactor.formValues.setJobName(e.target.value)}
            />
            <p>{interactor.formLabels.panelName}</p>
            <input
              className={styles.quotationInput}
              required
              value={interactor.formValues.panelName}
              onChange={(e) =>
                interactor.formValues.setPanelName(e.target.value)
              }
            />
            <p>{interactor.formLabels.specialRequirements}</p>
            <textarea
              className={styles.quotationTextField}
              value={interactor.formValues.specialRequirements}
              onChange={(e) =>
                interactor.formValues.setSpecialRequirements(e.target.value)
              }
            />
            <div className={styles.quotationButtons}>
              <SubmitButton width="auto">
                {interactor.formLabels.submitButton}
              </SubmitButton>
              <CancelButton
                onClick={interactor.handleCloseModal}
                margin="0 0 0 5px"
                width="auto"
              >
                {interactor.formLabels.cancelButton}
              </CancelButton>
            </div>
          </form>
        </QuotationModal>
        <iframe
          className={styles.application}
          title="Order"
          ref={interactor.iframeRef}
          src={"https://deployed.dynamaker.com/applications/4uAbo5SODYR/"}
        ></iframe>
      </div>
    </DefaultPageLayout>
  );
};
