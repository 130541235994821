import { products } from "resources/products";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Product } from "types";

export interface ISingleProductPageInteractor {
  pageName: string;
  productId: string | undefined;
  product: Product | undefined;
  content: SingleProductTabs;
  setContent: (content: SingleProductTabs) => void;
  contentButtons: SingleProductTabs[];
  notFound: string;
  description: { features: string; options: string };
  layout: {
    activeImage: number;
    setActiveImage: (image: number) => void;
    contactButton: { text: string; handleNavigateToContact: () => void };
  };
  related: { header: string; related: Product[] };
}

type SingleProductTabs = "Description" | "Specification";

export const useSingleProductPageInteractor =
  (): ISingleProductPageInteractor => {
    const { productId } = useParams();
    const [content, setContent] = useState<SingleProductTabs>("Description");
    const product = products.find((product) => product.id === productId);
    const related = products.filter((product) => product.id !== productId);
    const [activeImage, setActiveImage] = useState(1);

    const navigate = useNavigate();

    function handleNavigateToContact() {
      navigate("/contact-us");
    }

    return {
      pageName: "Product",
      productId: productId,
      product: product,
      content: content,
      setContent: setContent,
      contentButtons: ["Description", "Specification"],
      notFound: "Product not found",
      description: { features: "Standard Features", options: "Options" },
      layout: {
        activeImage: activeImage,
        setActiveImage: setActiveImage,
        contactButton: {
          text: "Contact Us",
          handleNavigateToContact: handleNavigateToContact,
        },
      },
      related: { header: "Related Products", related: related },
    };
  };
