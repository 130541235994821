import { SessionContext } from "components/authWrapper";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "services/api";
import { Order, User } from "types";

export interface IUserProfilePageInteractor {
  pageName: string;
  isAdmin: boolean;
  user: User | null;
  orders: Order[];
  ordersSection: {
    noOrders: string;
    header: string;
    ordersHeaders: string[];
    orderConfiguration: {
      box: string;
      mtc: string;
      switch: string;
      busAmpacity: string;
      voltage: string;
      feed: string;
      mainBoxes: string;
      editButtonText: string;
    };
  };
  usersSection: {
    header: string;
    info: {
      id: string;
      role: string;
      name: string;
      email: string;
      title: string;
      companyName: string;
      companyPhone: string;
      companyAddress: string;
      empty: string;
      reset: string;
    };
    email: string;
    setEmail: (email: string) => void;
    changeEmail: () => void;
    resetPassword: () => void;
  };
}

export const useUserProfilePageInteractor = (): IUserProfilePageInteractor => {
  const { isLoaded, session } = React.useContext(SessionContext);

  const { userId } = useParams();
  const [user, setUser] = useState<User | null>(null);
  const [orders, setOrders] = useState<Order[]>([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (userId) {
      API.user.getUser(userId).then((response) => {
        setUser(response);
        setEmail(response.email);
        setOrders(
          response.orders.sort(
            (a, b) => Date.parse(b.updated_at) - Date.parse(a.updated_at)
          )
        );
      });
    }
  }, [userId]);

  useEffect(() => {
    if (session?.role !== "ADMIN") {
      isLoaded && navigate("/");
    }
    setIsAdmin(true);
  }, [isLoaded]);

  function resetPassword() {
    if (user && isAdmin) {
      API.user.resetPassword(user.id).then(() => navigate(0));
    }
  }

  function changeEmail() {
    if (user && isAdmin) {
      API.user
        .updateUser(user.id, {
          email,
          full_name: user.full_name,
          job_title: user.job_title,
          company_name: user.company_name,
          company_phone: user.company_phone,
          company_address: user.company_address,
        })
        .then(() => navigate(0));
    }
  }

  return {
    pageName: "User",
    isAdmin: isAdmin,
    user: user,
    orders: orders,
    ordersSection: {
      noOrders: "User has no orders yet",
      header: "User's orders:",
      ordersHeaders: [
        "Order id",
        "Order status",
        "Order configuration",
        "Created at",
        "Last updated",
      ],
      orderConfiguration: {
        box: "Box size: ",
        mtc: "Metering transformer cabinet: ",
        switch: "Main switch: ",
        busAmpacity: "Bus ampacity: ",
        voltage: "Voltage: ",
        feed: "Feed: ",
        mainBoxes: "Main boxes: ",
        editButtonText: "Edit",
      },
    },
    usersSection: {
      header: "",
      info: {
        id: "Id:",
        role: "Role:",
        name: "Full name:",
        email: "Email:",
        title: "Job title:",
        companyName: "Company name:",
        companyPhone: "Company phone:",
        companyAddress: "Company address:",
        empty: "To be filled",
        reset: "Reset User Password",
      },
      email: email,
      setEmail: setEmail,
      changeEmail: changeEmail,
      resetPassword: resetPassword,
    },
  };
};
