import React from "react";

import { usePageLayoutInteractor } from "./interactor";
import { PageLayoutRouter } from "./router";

export interface IProps {
  pageName: string;
  children: React.ReactNode;
  header?: boolean;
}
export const DefaultPageLayout: React.FC<IProps> = ({
  pageName,
  children,
  header = true,
}) => {
  const interactor = usePageLayoutInteractor();
  return (
    <PageLayoutRouter
      interactor={interactor}
      pageName={pageName}
      children={children}
      header={header}
    />
  );
};
