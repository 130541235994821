// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".single-product-page_pageContent__hwEXE {\n  padding: 100px 20%;\n}\n.single-product-page_productNotFound__DJ2q0 {\n  margin: 100px 0;\n  text-align: center;\n}\n.single-product-page_contentButton__yhSl8 {\n  height: 40px;\n  font-size: 16px;\n  line-height: 40px;\n  font-weight: bold;\n  margin: 40px 30px 40px 0;\n}\n.single-product-page_activeContentButton__VZ0ER {\n  color: #ffc107;\n  border-bottom: 1px solid #ffc107;\n}\n\n@media (max-width: 1280px) {\n  .single-product-page_pageContent__hwEXE {\n    padding: 100px 10%;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/single-product-page/index.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,iBAAiB;EACjB,wBAAwB;AAC1B;AACA;EACE,cAAc;EACd,gCAAgC;AAClC;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".pageContent {\n  padding: 100px 20%;\n}\n.productNotFound {\n  margin: 100px 0;\n  text-align: center;\n}\n.contentButton {\n  height: 40px;\n  font-size: 16px;\n  line-height: 40px;\n  font-weight: bold;\n  margin: 40px 30px 40px 0;\n}\n.activeContentButton {\n  color: #ffc107;\n  border-bottom: 1px solid #ffc107;\n}\n\n@media (max-width: 1280px) {\n  .pageContent {\n    padding: 100px 10%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContent": "single-product-page_pageContent__hwEXE",
	"productNotFound": "single-product-page_productNotFound__DJ2q0",
	"contentButton": "single-product-page_contentButton__yhSl8",
	"activeContentButton": "single-product-page_activeContentButton__VZ0ER"
};
export default ___CSS_LOADER_EXPORT___;
