import { DefaultPageLayout } from "components/page-layout";
import React from "react";
import { Oval } from "react-loader-spinner";
import { Link } from "react-router-dom";
import styles from "./index.module.css";
import { IAllOrdersInteractor } from "./interactor";

export interface IProps {
  interactor: IAllOrdersInteractor;
}
export const AllOrdersRouter: React.FC<IProps> = ({ interactor }) => {
  if (!interactor.orders || !interactor.isAdmin) {
    return (
      <DefaultPageLayout pageName={interactor.pageName}>
        <div
          style={{
            display: "grid",
            justifyContent: "center",
            padding: "20vh 0 20vh ",
          }}
        >
          <Oval color="#f5f5f5" secondaryColor="#343434" />
        </div>
      </DefaultPageLayout>
    );
  }
  return (
    <DefaultPageLayout pageName={interactor.pageName}>
      <div className={styles.allOrdersPage}>
        <div className={styles.search}>
          <p>{interactor.searchBy.order_id}</p>
          <input
            value={interactor.orderId}
            onChange={(e) =>
              interactor.setOrderId(e.target.value.toLowerCase())
            }
          />
          <p>{interactor.searchBy.user}</p>
          <input
            value={interactor.ownerId}
            onChange={(e) =>
              interactor.setOwnerId(e.target.value.toLowerCase())
            }
          />
        </div>
        <div className={styles.pageContent}>
          <div className={styles.ordersHeader}>
            {interactor.ordersHeaders.map((header) => (
              <p key={header}>{header}</p>
            ))}
          </div>
          {interactor.orders.map((order) => {
            return (
              <div key={order.id} className={styles.orderField}>
                <p>
                  <Link className="link" to={`/orders/${order.id}`}>
                    {order.id}
                  </Link>
                </p>
                <p>
                  <Link className="link" to={`/clients/${order.owner.id}`}>
                    {order.owner.full_name}
                  </Link>
                </p>
                <div>
                  <select
                    className={styles.statusDropdown}
                    value={order.status}
                    onChange={(e) => {
                      interactor.updateOrderStatus(order.id, e.target.value);
                      interactor.getAllOrders();
                    }}
                  >
                    {interactor.orderStatuses.map((status) => (
                      <option key={status} value={status}>
                        {status}
                      </option>
                    ))}
                  </select>
                </div>
                <p>{order.create_at.slice(0, -8).replace("T", " ")}</p>
                <p>{order.updated_at.slice(0, -8).replace("T", " ")}</p>
              </div>
            );
          })}
        </div>
      </div>
    </DefaultPageLayout>
  );
};
