import { DefaultPageLayout } from "components/page-layout";
import React from "react";
import { Description } from "./components/description/description";
import { ProductLayout } from "./components/productLayout/productLayout";
import { Related } from "./components/related/related";
import { Specification } from "./components/specification/specification";
import styles from "./index.module.css";

import { ISingleProductPageInteractor } from "./interactor";

export interface IProps {
  interactor: ISingleProductPageInteractor;
}
export const SingleProductPageRouter: React.FC<IProps> = ({ interactor }) => {
  return (
    <DefaultPageLayout pageName={interactor.pageName}>
      {interactor.productId && interactor.product ? (
        <div className={styles.pageContent}>
          <ProductLayout interactor={interactor} />
          <div>
            {interactor.contentButtons.map((contentButton) => (
              <button
                key={contentButton}
                className={`${styles.contentButton} ${
                  interactor.content === contentButton &&
                  styles.activeContentButton
                }`}
                onClick={() => interactor.setContent(contentButton)}
              >
                {contentButton}
              </button>
            ))}
          </div>
          {interactor.content === "Description" && (
            <Description interactor={interactor} />
          )}
          {interactor.content === "Specification" && (
            <Specification interactor={interactor} />
          )}
          <Related interactor={interactor} />
        </div>
      ) : (
        <h1 className={styles.productNotFound}>{interactor.notFound}</h1>
      )}
    </DefaultPageLayout>
  );
};
