import { SessionContext } from "components/authWrapper";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "services/api";
import { User } from "types";

export interface IClientsPageInteractor {
  pageName: string;
  viewButtonText: string;
  clientsHeaders: string[];
  empty: string;
  isAdmin: boolean;
  users: User[];
}

export const useClientsPageInteractor = (): IClientsPageInteractor => {
  const { isLoaded, session } = React.useContext(SessionContext);
  const [isAdmin, setIsAdmin] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (session?.role === "ADMIN") {
      setIsAdmin(true);
      return;
    }
    isLoaded && navigate("/");
  }, [isLoaded]);

  useEffect(() => {
    API.user.getAllUsers().then((response) => setUsers(response));
  }, []);

  return {
    pageName: "Clients",
    viewButtonText: "View",
    clientsHeaders: [
      "Id",
      "Role",
      "Full name",
      "Email",
      "Job title",
      "Company name",
      "Company phone",
      "Company address",
    ],
    empty: "EMPTY",
    isAdmin: isAdmin,
    users: users,
  };
};
