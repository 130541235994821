import React from "react";
import styles from "./index.module.css";
import { SubmitButton } from "mui/submitButton";
import { IContactPageInteractor } from "../../interactor";

interface IProps {
  interactor: IContactPageInteractor;
}
export const ContactForm: React.FC<IProps> = ({ interactor }) => {
  return (
    <div className={styles.contactFormSection}>
      <h2>{interactor.contactForm.header}</h2>
      <form
        className={styles.contactForm}
        onSubmit={interactor.contactForm.onSendMessage}
      >
        <input
          placeholder={interactor.contactForm.name.placeholder}
          className={styles.formElement}
          value={interactor.contactForm.name.value}
          onChange={interactor.contactForm.name.onChange}
        />
        <input
          placeholder={interactor.contactForm.email.placeholder}
          className={styles.formElement}
          value={interactor.contactForm.email.value}
          onChange={interactor.contactForm.email.onChange}
        />
        <textarea
          placeholder={interactor.contactForm.message.placeholder}
          value={interactor.contactForm.message.value}
          onChange={interactor.contactForm.message.onChange}
          className={`${styles.messageField} ${styles.formElement}`}
        />
        <div>
          <SubmitButton width="170px">
            {interactor.contactForm.sendButtonText}
          </SubmitButton>
        </div>
      </form>
    </div>
  );
};
