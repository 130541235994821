import { SessionContext } from "components/authWrapper";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "services/api";
import { User } from "types";

export interface ILoginPageInteractor {
  pageName: string;
  session: User | null;
  email: { value: string; placeholder: string };
  password: { value: string; placeholder: string };
  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
  loginUser: () => void;
  buttonText: string;
  forgotPassword: string;
}

export const useLoginPageInteractor = (): ILoginPageInteractor => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  let { session } = React.useContext(SessionContext);
  const loginUser = () => {
    void API.auth.login(email, password).then(() => {
      navigate(0);
    });
  };

  useEffect(() => {
    if (session) {
      navigate("/");
    }
  }, [session]);

  return {
    session: session,
    pageName: "Login",
    email: { value: email, placeholder: "Your email" },
    password: { value: password, placeholder: "Your password" },
    setEmail: setEmail,
    setPassword: setPassword,
    loginUser: loginUser,
    buttonText: "Login",
    forgotPassword: "Forgot password?",
  };
};
