export type Product = {
  id: string;
  name: string;
  images: string[];
  price: number;
  prevPrice: number;
  description: string;
  shortDescription: string;
  capabilityNames: string[];
  capabilities: string[];
  features: string[];
  options: string[];
};

export interface User {
  id: string;
  email: string;
  full_name: string;
  job_title: string | null;
  company_name: string | null;
  company_phone: string | null;
  company_address: string | null;
  role: UserRole;
  orders: Order[];
}

export type CreateUserData = Omit<User, "id" | "orders" | "role">;

export enum UserRole {
  CLIENT = "CLIENT",
  ADMIN = "ADMIN",
}

export interface Order {
  id: string;
  owner: User;
  status: OrderStatus;
  job_name: string;
  panel_name: string;
  special_requirements: string;
  configuration: string;
  create_at: string;
  updated_at: string;
}

export enum OrderStatus {
  DRAFT = "DRAFT",
  IN_PROGRESS = "IN_PROGRESS",
  DELIVERING = "DELIVERING",
  COMPLETED = "COMPLETED",
}

declare global {
  namespace NodeJS {
    interface ProcessEnv {
      REACT_APP_GOOGLE_MAPS_API_KEY: string;
      REACT_APP_API_HOST: string;
    }
  }
}

export type ModuleInfo = {
  module_id: number;
  module_type: string;
  position_of_module: { x: number; y: number };
};

export type RailInfo = {
  rail_id: number;
  modules: ModuleInfo[];
};

export type HookEvent = {
  data: string;
  origin: string;
};
