import React from "react";
import { AppFooter } from "./components/appFooter/Footer";
import { AppHeader } from "./components/appHeader/Header";
import { PageHeader } from "./components/pageHeader";

import { IPageLayoutInteractor } from "./interactor";

export interface IProps {
  interactor: IPageLayoutInteractor;
  pageName: string;
  children: React.ReactNode;
  header?: boolean;
}
export const PageLayoutRouter: React.FC<IProps> = ({
  interactor,
  pageName,
  children,
  header,
}) => {
  return (
    <>
      <AppHeader interactor={interactor} />
      {header && <PageHeader pageName={pageName} />}
      {children}
      <AppFooter interactor={interactor} />
    </>
  );
};
