import { Button, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserRole } from "types";
import { ReactComponent as UDTriangle } from "assets/triangleDown.svg";

export function HeaderMenu({
  welcomingMessage,
  handleLogout,
  role,
}: {
  welcomingMessage: string;
  handleLogout: () => void;
  role: UserRole;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  return (
    <div>
      <Button
        style={{
          fontSize: "18px",
          textTransform: "none",
          color: "#000000",
          fontWeight: "400",
          lineHeight: "24px",
        }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {welcomingMessage}
        <UDTriangle style={{ width: "10px" }} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {role === "ADMIN" ? (
          <div>
            <MenuItem
              style={{
                fontSize: "15px",
                textTransform: "none",
                textAlign: "left",
              }}
              onClick={() => {
                handleClose();
                navigate("/clients/create");
              }}
            >
              Create user
            </MenuItem>
            <MenuItem
              style={{
                fontSize: "15px",
                textTransform: "none",
                textAlign: "left",
              }}
              onClick={() => {
                handleClose();
                navigate("/clients");
              }}
            >
              User list
            </MenuItem>
            <MenuItem
              style={{
                fontSize: "15px",
                textTransform: "none",
                textAlign: "left",
              }}
              onClick={() => {
                handleClose();
                navigate("/orders/all");
              }}
            >
              Order list
            </MenuItem>
          </div>
        ) : (
          <MenuItem
            style={{
              fontSize: "15px",
              textTransform: "none",
              textAlign: "left",
            }}
            onClick={() => {
              handleClose();
              navigate("/orders");
            }}
          >
            My orders
          </MenuItem>
        )}
        <MenuItem
          style={{
            fontSize: "15px",
            textTransform: "none",
            textAlign: "left",
          }}
          onClick={() => {
            handleClose();
            handleLogout();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}
