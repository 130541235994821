import React from "react";

import { useProductsPageInteractor } from "./interactor";
import { ProductsPageRouter } from "./router";

export interface IProps {}
export const ProductsPage: React.FC<IProps> = () => {
  const interactor = useProductsPageInteractor();
  return <ProductsPageRouter interactor={interactor} />;
};
