import React from "react";

import { useCreateAccountPageInteractor } from "./interactor";
import { CreateAccountPageRouter } from "./router";

export interface IProps {}
export const CreateAccountPage: React.FC<IProps> = () => {
  const interactor = useCreateAccountPageInteractor();
  return <CreateAccountPageRouter interactor={interactor} />;
};
