// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login-page_loginForm__czpIm {\n  padding: 70px 0;\n  display: grid;\n  grid-template-columns: 1fr 400px 1fr;\n  gap: 20px;\n}\n.login-page_loginForm__czpIm > * {\n  grid-column: 2;\n}\n.login-page_loginInput__kK4BO {\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  padding: 10px;\n  font-size: 15px;\n}\n.login-page_loginOrRestore__gEads {\n  position: relative;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  align-items: center;\n}\n.login-page_loginOrRestore__gEads * {\n  text-decoration: none;\n}\n\n.login-page_forgotPassword__lF2Z7 {\n  font-size: 15px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/login-page/index.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,oCAAoC;EACpC,SAAS;AACX;AACA;EACE,cAAc;AAChB;AACA;EACE,oCAAoC;EACpC,aAAa;EACb,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,qBAAqB;AACvB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".loginForm {\n  padding: 70px 0;\n  display: grid;\n  grid-template-columns: 1fr 400px 1fr;\n  gap: 20px;\n}\n.loginForm > * {\n  grid-column: 2;\n}\n.loginInput {\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  padding: 10px;\n  font-size: 15px;\n}\n.loginOrRestore {\n  position: relative;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  align-items: center;\n}\n.loginOrRestore * {\n  text-decoration: none;\n}\n\n.forgotPassword {\n  font-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginForm": "login-page_loginForm__czpIm",
	"loginInput": "login-page_loginInput__kK4BO",
	"loginOrRestore": "login-page_loginOrRestore__gEads",
	"forgotPassword": "login-page_forgotPassword__lF2Z7"
};
export default ___CSS_LOADER_EXPORT___;
