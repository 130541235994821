import { DefaultPageLayout } from "components/page-layout";
import React from "react";
import { Link } from "react-router-dom";
import { LinkButton } from "mui/linkButton";
import styles from "./index.module.css";

import { IMyOrdersPageInteractor } from "./interactor";
import { Oval } from "react-loader-spinner";

export interface IProps {
  interactor: IMyOrdersPageInteractor;
}
export const MyOrdersPageRouter: React.FC<IProps> = ({ interactor }) => {
  if (!interactor.orders)
    return (
      <DefaultPageLayout pageName={interactor.pageName}>
        <div
          style={{
            display: "grid",
            justifyContent: "center",
            padding: "20vh 0 20vh ",
          }}
        >
          <Oval color="#f5f5f5" secondaryColor="#343434" />
        </div>
      </DefaultPageLayout>
    );
  if (interactor.orders.length === 0) {
    return (
      <DefaultPageLayout pageName={interactor.pageName}>
        <h1 className={styles.noOrdersMessage}>{interactor.noOrders}</h1>
      </DefaultPageLayout>
    );
  }

  return (
    <DefaultPageLayout pageName={interactor.pageName}>
      <div className={styles.pageContent}>
        <div className={styles.ordersHeader}>
          {interactor.ordersHeaders.map((header) => {
            return <p key={header}>{header}</p>;
          })}
        </div>
        {interactor.orders.map((order) => {
          const config = JSON.parse(atob(order.configuration));
          return (
            <div key={order.id} className={styles.orderField}>
              <p>
                <Link className="link" to={`/orders/${order.id}`}>
                  {order.id}
                </Link>
              </p>
              <p>{order.status}</p>
              <div className={styles.orderConfiguration}>
                <p>
                  {interactor.orderConfiguration.job_name}
                  {order.job_name}
                </p>
                <p>
                  {interactor.orderConfiguration.panel_name}
                  {order.panel_name}
                </p>
                <p>
                  {interactor.orderConfiguration.special_requirements}
                  {order.special_requirements}
                </p>
                <br />
                <p>
                  {interactor.orderConfiguration.box}
                  {config.box_size}
                </p>
                <p>
                  {interactor.orderConfiguration.mtc}
                  {config.metering_transformer_cabinet ? "Yes" : "No"}
                </p>
                <p>
                  {interactor.orderConfiguration.switch}
                  {config.main_switch}
                </p>
                <p>
                  {interactor.orderConfiguration.busAmpacity}
                  {config.busAmpacity}
                </p>
                <p>
                  {interactor.orderConfiguration.voltage}
                  {config.voltage}
                </p>
                <p>
                  {interactor.orderConfiguration.feed}
                  {config.feed}
                </p>
                <p>
                  {interactor.orderConfiguration.mainBoxes}
                  {config.main_box_quantity}
                </p>
                {order.status === "DRAFT" && (
                  <Link className="link" to={`/orders/${order.id}/edit`}>
                    <LinkButton>
                      {interactor.orderConfiguration.editButtonText}
                    </LinkButton>
                  </Link>
                )}
              </div>
              <p>{order.create_at.slice(0, -8).replace("T", " ")}</p>
              <p>{order.updated_at.slice(0, -8).replace("T", " ")}</p>
            </div>
          );
        })}
      </div>
    </DefaultPageLayout>
  );
};
