import { DefaultPageLayout } from "components/page-layout";
import React from "react";
import { Link } from "react-router-dom";
import { LinkButton } from "mui/linkButton";
import styles from "./index.module.css";

import { IClientsPageInteractor } from "./interactor";
import { Oval } from "react-loader-spinner";

export interface IProps {
  interactor: IClientsPageInteractor;
}
export const ClientsPageRouter: React.FC<IProps> = ({ interactor }) => {
  if (!interactor.isAdmin)
    return (
      <div
        style={{
          display: "grid",
          justifyContent: "center",
          padding: "20vh 0 20vh ",
        }}
      >
        <Oval color="#f5f5f5" secondaryColor="#343434" />
      </div>
    );

  return (
    <DefaultPageLayout pageName={interactor.pageName}>
      <div className={styles.clientsTable}>
        <div className={styles.clientsTableHeader}>
          {interactor.clientsHeaders.map((header) => (
            <p key={header}>{header}</p>
          ))}
        </div>
        <div className={styles.clientsList}>
          {interactor.users.map((user) => {
            return (
              <div key={user.id}>
                <p>{user.id}</p>
                <p>
                  {user.role}
                  <Link to={`/clients/${user.id}`}>
                    <LinkButton>{interactor.viewButtonText}</LinkButton>
                  </Link>
                </p>
                <p>{user.full_name}</p>
                <p>{user.email}</p>
                <p>{user.job_title ? user.job_title : interactor.empty}</p>
                <p>
                  {user.company_name ? user.company_name : interactor.empty}
                </p>
                <p>
                  {user.company_phone ? user.company_phone : interactor.empty}
                </p>
                <p>
                  {user.company_address
                    ? user.company_address
                    : interactor.empty}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </DefaultPageLayout>
  );
};
