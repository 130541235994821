import { Button } from "@mui/material";

export function CancelButton({
  children,
  width,
  margin,
  onClick,
}: {
  children: string;
  width: string;
  margin: string;
  onClick: () => void;
}) {
  return (
    <Button
      variant="contained"
      color="secondary"
      style={{
        height: "50px",
        borderRadius: 0,
        fontSize: "15px",
        lineHeight: "15px",
        fontWeight: "600",
        width: width,
        margin: margin,
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}
