import { SessionContext } from "components/authWrapper";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "services/api";
import { Order, OrderStatus } from "types";

export interface ISingleOrderPageInteractor {
  pageName: string;
  orderId: string | undefined;
  order: Order | undefined;
  isAllowed: boolean;
  isAdmin: boolean;
  configuration: string;
  status: OrderStatus;
  setStatus: React.ChangeEventHandler<HTMLSelectElement>;
  updateOrderStatus: () => void;
  orderInfoHeader: string;
  orderHeaders: {
    id: string;
    orderer: string;
    special_requirements: string;
    status: string;
    created: string;
    updated: string;
    job_name: string;
    panel_name: string;
  };
  orderStatuses: OrderStatus[];
  updateButtonText: string;
  orderViewHeader: string;
}

export const useSingleOrderPageInteractor = (): ISingleOrderPageInteractor => {
  const { isLoaded, session } = React.useContext(SessionContext);

  const { orderId } = useParams();
  const [isAllowed, setIsAllowed] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoaded) return;
    if (
      session?.role !== "ADMIN" &&
      !session?.orders.find((order: Order) => {
        return order.id === orderId;
      })
    ) {
      navigate("/");
      return;
    }
    setIsAllowed(true);
    if (session?.role === "ADMIN") {
      setIsAdmin(true);
    }
  }, [session, isLoaded]);

  const [order, setOrder] = useState<Order>();
  const [status, setStatus] = useState<OrderStatus>(OrderStatus.DRAFT);
  const [configuration, setConfiguration] = useState("");

  useEffect(() => {
    if (isLoaded) {
      if (orderId) {
        API.order.getOrderById(orderId).then((response: Order) => {
          setOrder(response);
          setConfiguration(response.configuration);
        });
        return;
      }
      navigate("/");
    }
  }, [orderId, isLoaded]);

  useEffect(() => {
    if (order) {
      setStatus(order.status);
    }
  }, [order]);

  function updateOrderStatus() {
    if (order) {
      API.order.updateOrderStatus(order.id, status);
    }
  }

  return {
    pageName: "Order",
    orderId: orderId,
    order: order,
    isAllowed: isAllowed,
    isAdmin: isAdmin,
    configuration: configuration,
    status: status,
    setStatus: (e) => {
      setStatus(e.target.value as OrderStatus);
    },
    updateOrderStatus: updateOrderStatus,
    orderInfoHeader: "Order info",
    orderHeaders: {
      id: "ID",
      orderer: "Ordered by",
      special_requirements: "Special requirements",
      status: "Order status",
      created: "Created at",
      updated: "Updated at",
      job_name: "Job name",
      panel_name: "Panel name",
    },
    orderStatuses: [
      OrderStatus.DRAFT,
      OrderStatus.IN_PROGRESS,
      OrderStatus.DELIVERING,
      OrderStatus.COMPLETED,
    ],
    updateButtonText: "Update",
    orderViewHeader: "Order 3D view",
  };
};
