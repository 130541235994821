import { SessionContext } from "components/authWrapper";
import React, { RefObject, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "services/api";
import { Order } from "types";
import { useMessageListener } from "utils/useMessageListener";

export interface IEditOrderPageInteractor {
  pageName: string;
  isAuth: boolean;
  orderId: string | undefined;
  configuration: string;
  isModalOpen: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  handleSubmitModal: () => void;
  formLabels: {
    jobName: string;
    panelName: string;
    specialRequirements: string;
    submitButton: string;
    cancelButton: string;
  };
  formValues: {
    jobName: string;
    setJobName: (value: string) => void;
    panelName: string;
    setPanelName: (value: string) => void;
    specialRequirements: string;
    setSpecialRequirements: (value: string) => void;
  };
  iframeRef: RefObject<HTMLIFrameElement>;
}

export const useEditOrderPageInteractor = (): IEditOrderPageInteractor => {
  const iframeRef = React.createRef<HTMLIFrameElement>();

  const { isLoaded, session } = React.useContext(SessionContext);

  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    if (!session) {
      setIsAuth(false);
      return;
    }
    setIsAuth(true);
  }, [session]);

  const { orderId } = useParams();
  const [configuration, setConfiguration] = useState("");
  const [jobName, setJobName] = useState("");
  const [panelName, setPanelName] = useState("");
  const [specialRequirements, setSpecialRequirements] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => {
    setJobName("");
    setPanelName("");
    setSpecialRequirements("");
    setIsModalOpen(false);
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoaded && !session) {
      navigate("/");
    }
    if (orderId && isLoaded) {
      API.order
        .getOrderById(orderId)
        .then((order: Order) => {
          if (
            order.status !== "DRAFT" ||
            !session?.orders.find((order: Order) => {
              return order.id === orderId;
            })
          ) {
            navigate("/orders/create");
          }
          setConfiguration(order.configuration);
        })
        .catch(() => navigate("/orders/create"));
    }
  }, [orderId, isLoaded]);

  useMessageListener((data) => {
    handleOpenModal();
    setConfiguration(data);
  });
  useMessageListener((data) => {
    if (orderId && configuration) {
      handleOpenModal();
      setConfiguration(data);
    }
  });

  function handleSubmitModal() {
    if (configuration && jobName && panelName && orderId) {
      if (iframeRef.current && iframeRef.current.contentWindow) {
        iframeRef.current.contentWindow.postMessage(
          btoa(JSON.stringify({ job_name: jobName, panel_name: panelName })),
          "*"
        );
      }
      API.order
        .updateOrderConfiguration(orderId, {
          configuration: configuration,
          job_name: jobName,
          panel_name: panelName,
          special_requirements: specialRequirements,
        })
        .then(handleCloseModal);
    }
  }

  return {
    pageName: "Edit order",
    isAuth: isAuth,
    orderId: orderId,
    configuration: configuration,
    isModalOpen: isModalOpen,
    handleOpenModal: handleOpenModal,
    handleCloseModal: handleCloseModal,
    handleSubmitModal: handleSubmitModal,
    formLabels: {
      jobName: "Job name:",
      panelName: "Panel name:",
      specialRequirements: "Special requirements:",
      submitButton: "Submit",
      cancelButton: "Cancel",
    },
    formValues: {
      jobName: jobName,
      setJobName: setJobName,
      panelName: panelName,
      setPanelName: setPanelName,
      specialRequirements: specialRequirements,
      setSpecialRequirements: setSpecialRequirements,
    },
    iframeRef: iframeRef,
  };
};
