import { Button } from "@mui/material";

export function SubmitButton({
  width,
  children,
}: {
  width: string;
  children: string;
}) {
  return (
    <Button
      variant="contained"
      color="primary"
      type="submit"
      style={{
        height: "50px",
        borderRadius: 0,
        fontSize: "15px",
        lineHeight: "15px",
        fontWeight: "600",
        width: width,
      }}
    >
      {children}
    </Button>
  );
}
