import React from "react";

import { useCreateOrderPageInteractor } from "./interactor";
import { CreateOrderPageRouter } from "./router";

export interface IProps {}
export const CreateOrderPage: React.FC<IProps> = () => {
  const interactor = useCreateOrderPageInteractor();
  return <CreateOrderPageRouter interactor={interactor} />;
};
