import { SmallButton } from "mui/smallButton";
import { IProps } from "pages/user-profile-page/router";
import styles from "./userInfo.module.css";

export const UserInfo: React.FC<IProps> = ({ interactor }) => {
  if (!interactor.user) return null;
  return (
    <div className={styles.userInfoPage}>
      <h2 className={styles.pageHeader}>{interactor.usersSection.header}</h2>
      <div className={styles.userInfo}>
        <div>
          <p>
            <b>{interactor.usersSection.info.id}</b>
          </p>
          <p>{interactor.user.id}</p>
        </div>
        <div>
          <p>
            <b>{interactor.usersSection.info.role}</b>
          </p>
          <p>{interactor.user.role}</p>
        </div>
        <div>
          <p>
            <b>{interactor.usersSection.info.name}</b>
          </p>
          <p>{interactor.user.full_name}</p>
        </div>
        <div>
          <p>
            <b>{interactor.usersSection.info.email}</b>
          </p>
          {interactor.isAdmin ? (
            <>
              <input
                className={styles.emailInput}
                value={interactor.usersSection.email}
                onChange={(e) =>
                  interactor.usersSection.setEmail(e.target.value)
                }
              />
              <br />
              <SmallButton
                margin="0"
                onClick={interactor.usersSection.changeEmail}
              >
                Save
              </SmallButton>
            </>
          ) : (
            <p>{interactor.user.email}</p>
          )}
        </div>
        <div>
          <p>
            <b>{interactor.usersSection.info.title}</b>
          </p>
          <p>
            {interactor.user.job_title
              ? interactor.user.job_title
              : interactor.usersSection.info.empty}
          </p>
        </div>
        <div>
          <p>
            <b>{interactor.usersSection.info.companyName}</b>
          </p>
          <p>
            {interactor.user.company_name
              ? interactor.user.company_name
              : interactor.usersSection.info.empty}
          </p>
        </div>
        <div>
          <p>
            <b>{interactor.usersSection.info.companyPhone}</b>
          </p>
          <p>
            {interactor.user.company_phone
              ? interactor.user.company_phone
              : interactor.usersSection.info.empty}
          </p>
        </div>
        <div>
          <p>
            <b>{interactor.usersSection.info.companyAddress}</b>
          </p>
          <p>
            {interactor.user.company_address
              ? interactor.user.company_address
              : interactor.usersSection.info.empty}
          </p>
        </div>
      </div>
      {interactor.isAdmin && (
        <div style={{ textAlign: "center" }}>
          <SmallButton
            margin="10px"
            onClick={interactor.usersSection.resetPassword}
          >
            {interactor.usersSection.info.reset}
          </SmallButton>
        </div>
      )}
    </div>
  );
};
