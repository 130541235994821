import styles from "./header.module.css";
import logo from "assets/atlas_power_logo_blue_orange.png";
import { Link } from "react-router-dom";
import { ReactComponent as UserIcon } from "assets/user.svg";
import { HeaderMenu } from "mui/headerMenu";
import React from "react";
import { IPageLayoutInteractor } from "components/page-layout/interactor";

interface IProps {
  interactor: IPageLayoutInteractor;
}

export const AppHeader: React.FC<IProps> = ({ interactor }) => {
  return (
    <div className={styles.pageHeader}>
      <img src={logo} alt="logo" className={styles.logo} />
      <div className={styles.navigation}>
        {interactor.appHeader.navButtons.map((button) => {
          return (
            button && (
              <Link key={button.buttonText} to={button.link}>
                <button className={styles.navButton}>
                  {button.buttonText}
                </button>
              </Link>
            )
          );
        })}
      </div>
      {interactor.session ? (
        <div className={styles.logOutWindow}>
          <HeaderMenu
            welcomingMessage={interactor.getUserName()}
            handleLogout={interactor.handleLogout}
            role={interactor.session.role}
          />
        </div>
      ) : (
        <Link to={interactor.appHeader.loginButton.link}>
          <button className={styles.logIn}>
            <UserIcon />
            {interactor.appHeader.loginButton.buttonText}
          </button>
        </Link>
      )}
    </div>
  );
};
