import styles from "./services.module.css";
import customBusbar from "../../assets/customBusbar.png";
import { IHomePageInteractor } from "../..//interactor";

interface IProps {
  interactor: IHomePageInteractor;
}

export const ServicesHeader: React.FC<IProps> = ({ interactor }) => {
  return (
    <div className={styles.servicesHeader}>
      {interactor.services.header.map((service) => {
        return (
          <div key={service.name} className={styles.service}>
            <service.svg className={styles.serviceIcon} />
            <div>
              <h2 className={styles.serviceName}>{service.name}</h2>
            </div>
          </div>
        );
      })}

      <div className={styles.service}>
        <img
          src={customBusbar}
          alt={interactor.services.customBusbar}
          className={styles.serviceIcon}
        />
        <div>
          <h2 className={styles.serviceName}>
            {interactor.services.customBusbar}
          </h2>
        </div>
      </div>
    </div>
  );
};

export const ServicesContent: React.FC<IProps> = ({ interactor }) => {
  return (
    <div className={styles.servicesContent}>
      <h1>{interactor.services.contentHeader}</h1>
      <div className={styles.servicesDescription}>
        {interactor.services.content.map((service) => {
          return (
            <div key={service.name}>
              <img src={service.img} alt={service.name} width="100%" />
              <h2 className={styles.serviceDescriptionHeader}>
                {service.name}
              </h2>
              <p className={styles.serviceDescriptionContent}>{service.text}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
